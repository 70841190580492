import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  ApiBaseQuery, ApiTagTypes, baseQuery, tagTypes,
} from './baseQuery';
import { accessQueries } from './modules/access/accessQueries';
import { ticketsQueries } from './modules/tickets/ticketsQueries';
import { metersQueries } from './modules/meters/metersQueries';
import { resolutionsQueries } from './modules/resolutions/resolutionsQueries';
import { userQueries } from './modules/user/userQueries';
import { announcementsQueries } from './modules/announcements/announcementsQueries';
import { residencyQueries } from './modules/residency/residencyQueries';
import { tenantsQueries } from './modules/tenants/tenantsQueries';
import { venuesQueries } from './modules/venues/venuesQueries';
import { homeownerAssociationsQueries } from './modules/homeownerAssociations/homeownerAssociationsQueries';
import { settlementsQueries } from './modules/settlements/settlementsQueries';
import { employeesQueries } from './modules/employees/employeesQueries';
import { tasksQueries } from './modules/tasks/tasksQueries';
import { companyQueries } from './modules/company/companyQueries';
import { documentsQueries } from './modules/documents/documentsQueries';

export const appApi = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes,
  endpoints: (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
    ...userQueries(builder),
    ...accessQueries(builder),
    ...metersQueries(builder),
    ...ticketsQueries(builder),
    ...resolutionsQueries(builder),
    ...announcementsQueries(builder),
    ...residencyQueries(builder),
    ...tenantsQueries(builder),
    ...venuesQueries(builder),
    ...homeownerAssociationsQueries(builder),
    ...settlementsQueries(builder),
    ...employeesQueries(builder),
    ...tasksQueries(builder),
    ...companyQueries(builder),
    ...documentsQueries(builder),
  }),
});

export const {
  useGetFullUserAccountQuery,
  useGetProfileQuery,
  usePostProfileMutation,
  usePostChangePasswordMutation,
  usePutChangeUserPasswordMutation,
  useLogoutMutation,
  useGetActivityLogQuery,
  useGetUsersAccountsQuery,
  useGetUserAccountQuery,
  usePostUserAccountMutation,
  usePutUserAccountMutation,
  useGetRolesForUserQuery,
  useChangeUserActivityStatusMutation,
  useGetAccessGroupsQuery,
  usePatchColorPaletteMutation,

  useGetAccountsQuery,
  useGetAccountQuery,
  usePostAssignRoleMutation,
  usePostAssignPermissionsMutation,
  useGetRolesQuery,
  usePostChangeRoleMutation,
  usePostCreateRoleMutation,
  useDeleteRoleMutation,

  useGetMetersQuery,
  usePostMeasurementsMutation,
  useGetMetersVenuesListQuery,

  useGetTicketsQuery,
  useGetTicketQuery,
  usePostTicketMutation,
  usePostTicketCommentMutation,
  useGetSelectedTicketsColumnsQuery,
  usePostSelectedTicketsColumnsMutation,
  usePostChangeTicketMutation,
  useGetTicketCategoriesQuery,
  usePostTicketCategoryMutation,
  usePatchTicketCategoryMutation,
  useDeleteTicketCategoryMutation,
  useGetTicketStatusesQuery,
  useGetFullTicketStatusesQuery,
  usePutStatusMutation,
  usePostTaskForTicketMutation,

  useGetTasksQuery,
  useGetTaskQuery,
  useGetSelectedTasksColumnsQuery,
  usePostSelectedTasksColumnsMutation,
  useGetTaskCategoriesQuery,
  usePostTaskCommentMutation,
  usePostChangeTaskMutation,
  usePostTaskCategoryMutation,
  usePatchTaskCategoryMutation,
  useDeleteTaskCategoryMutation,
  useGetTaskStatusesQuery,
  useGetFullTaskStatusesQuery,

  useGetDocumentsQuery,
  useGetDocumentQuery,
  usePostDocumentMutation,
  usePostChangeDocumentMutation,
  usePostRevokeDocumentMutation,
  useGetDocumentsCategoriesQuery,
  useGetDocumentStatusesQuery,
  useGetFullDocumentStatusesQuery,
  usePostDocumentCommentMutation,
  useGetSelectedDocumentsColumnsQuery,
  usePostSelectedDocumentsColumnsMutation,
  usePostTaskForDocumentMutation,
  usePostDocumentCategoryMutation,
  usePatchDocumentCategoryMutation,
  useDeleteDocumentCategoryMutation,

  useGetResolutionsQuery,
  useGetResolutionQuery,
  usePostContentAcceptedMutation,
  usePostResolutionVoteValueMutation,
  useGetResolutionVotingsListQuery,

  useGetAnnouncementsQuery,
  useGetAnnouncementQuery,
  usePostAnnouncementMutation,
  useChangeAnnouncementMutation,
  usePostMarkReadAnnouncementMutation,
  usePostArchiveAnnouncementMutation,
  useGetAnnouncementsAuthorsQuery,
  useGetAnnouncementRecipientsQuery,
  useGetRecipientsForFilterQuery,

  useGetResidencyQuery,
  usePostResidencyUpdateMutation,

  useGetTenantsQuery,

  useGetVenuesQuery,
  useGetVenueDetailsQuery,

  useGetHomeownerAssociationsQuery,

  useGetPaymentsQuery,
  useGetPaymentsHistoryQuery,
  useGetSettlementsBalanceQuery,
  useGetSettlementsYearsQuery,
  useGetSettlementsTitlesQuery,
  useGetMonthsSettlementsBalanceQuery,
  useGetTitleSettlementsBalanceQuery,
  usePatchDocumentReadMutation,
  usePostMakePaymentMutation,
  useGetBMPaymentQuery,
  useGetPaymentsBalanceQuery,
  useGetSettlementsVenuesQuery,

  useGetCompanySettingsQuery,
  useGetCompanyDataQuery,
  usePutCompanyDetailsMutation,
  usePostCompanyAttachmentsMutation,
  usePutColorPaletteMutation,
  useGetMailServerConfigQuery,
  usePutChangeMailServerConfigMutation,
  usePostTestMailServerConfigMutation,

  useGetEmployeesQuery,

  useGetJournalsQuery,
  useGetFullJournalsQuery,
  useGetJournalQuery,
  useChangeJournalMutation,
  usePostJournalMutation,
  useDeleteJournalMutation,
} = appApi;

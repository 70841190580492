/* eslint-disable react/jsx-props-no-spreading */
import { RegisterOptions, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as React from 'react';
import { InputError, InputLabel } from '../InputLabel';

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none`;

interface FormTextareaProps {
  id: string,
  label?: string,
  className?: string,
  inputClassName?: string,
  disabled?: boolean,
  placeholder?: string,
  options?: RegisterOptions,
  rows?: number
  ariaLabel?: string
}
const FormTextarea = ({
  id, label, className, inputClassName, disabled, options, placeholder, rows = 6, ariaLabel,
}: FormTextareaProps) => {
  const { register, formState } = useFormContext();

  return (
    <div className={`flex-col items-start inline-block ${className}`}>
      {label && <InputLabel id={id} label={label} />}
      <textarea
        id={id}
        cols={56}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
        {...register(id, options)}
        className={classnames(
          inputDefaultStyles,
          inputClassName,
          { 'border-interactions-input-error border-2': formState.errors[id] },
        )}
        aria-label={ariaLabel}
      />
      {formState.errors[id]?.message && <InputError message={formState.errors[id]?.message as string} />}
    </div>
  );
};

export default FormTextarea;

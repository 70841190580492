'use client';

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import useMessages from '../../../hooks/useMessages';
import { usePermissions } from '../../../hooks/usePermissions';
import { useGetAnnouncementQuery } from '../../../api/appApi';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Card, { ArticleCard } from '../../../components/Card/Card';
import Chip from '../../../components/Chip/Chip';
import AttachmentPreview from '../../../components/AttachmentPreview/AttachmentPreview';
import { AnnouncementData, AnnouncementRecipientsData } from '../../../api/modules/announcements/announcementsTypes';
import SpeakerIcon from '../../../icons/volume.svg';
import Layout from '../../../components/Layout/Layout';
import RecipientGroupItem from './ReciepientsGroupItem';
import Button from '../../../components/Button/Button';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';

const AnnouncementPage = () => {
  const { id } = useParams();
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const navigate = useNavigate();
  const { data, isSuccess } = useGetAnnouncementQuery(id);
  const { hasPermission, hasRoleType } = usePermissions();
  if (!data) return null;

  const {
    announcementNumber, title, description, category, createdAt, attachments, author, recipients,
  } = isSuccess ? data : {} as AnnouncementData;

  const {
    homeownerAssociations, employees, estates, buildings, tenants, roles,
  } = recipients || {} as AnnouncementRecipientsData;

  const hasRecipients = recipients !== undefined && Object.keys(recipients).length > 0;

  return (
    <Layout currentPage="announcements">
      <PageHeader
        currentPage="announcements"
        pageTitle={`${getMessage(`announcements.announcement.category.${category}`)} ${announcementNumber}`}
        path="/aktualnosci"
      >
        {hasRoleType('worker') && (
        <Button
          onClick={() => navigate(`/aktualnosci/${id}/edytuj`)}
          className="py-1"
          size="small"
        >
          {getMessage('announcements.notice.edit')}
        </Button>
        )}
      </PageHeader>
      <ContentWrapper>
        <ArticleCard className="col-span-full">
          <div>
            <h2 className="text-header-sm">{title}</h2>
            <div className="flex justify-between my-4">
              <div className="flex items-center">
                <SpeakerIcon className="text-main mr-1" />
                <p className="text-text-lg-medium text-main">
                  <span className="visually-hidden">Kategoria</span>
                  {getMessage(`announcements.announcement.category.${category}`)}
                </p>
              </div>
              <Chip chipStyle="ghost" className="min-w-fit h-fit" screenReaderOnlyText="Data utworzenia">
                {format(new Date(createdAt), 'dd-MM-yyyy')}
              </Chip>
            </div>
          </div>
          <div className="border-y py-6">
            <p className="text-typography-dark text-text-lg-medium whitespace-pre-line">
              {description}
            </p>
            {attachments.length > 0 && (
              <ol className="flex flex-wrap mt-12">
                {attachments.map(({ filename, path, contentType }) => (
                  <AttachmentPreview key={path} filename={filename} path={path} contentType={contentType} />))}
              </ol>
            )}
          </div>
          <p className="text-text-md-medium text-right text-typography-dark mt-4">
            <span className="visually-hidden">Autor</span>
            {author.toUpperCase()}
          </p>
        </ArticleCard>
        {hasRoleType('worker') && hasPermission('announcements_notice_add') && hasRecipients && (
          <>
            <p className="text-header-sm my-4 col-span-full">{getMessage('announcements.notice.form.receiversGroups')}</p>
            <Card className="col-span-full">
              {employees && <RecipientGroupItem label="employees" recipientGroup={employees} />}
              {hoaMode && homeownerAssociations && <RecipientGroupItem label="homeownerAssociations" recipientGroup={homeownerAssociations} />}
              {estates && <RecipientGroupItem label="estates" recipientGroup={estates} />}
              {buildings && <RecipientGroupItem label="buildings" recipientGroup={buildings} />}
              {tenants && <RecipientGroupItem label="tenants" recipientGroup={tenants} />}
              {roles && <RecipientGroupItem label="roles" recipientGroup={roles} />}
            </Card>
          </>
        )}
      </ContentWrapper>
    </Layout>
  );
};

export default AnnouncementPage;

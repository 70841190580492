import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  CompanySettings,
  ApiCompanySettingsResponse,
  ApiCompanyDetailsResponse,
  FormCompanyDetails,
  CompanyData,
  ColorPalette,
  MailServerConfig, ApiMailServerConfigResponse, ApiPutMailServerConfig,
} from './companyTypes';
import {
  translateCompanySettings, translateCompanyData, translateMailServerConfig,
} from './companyTranslator';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';

export const companyQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getCompanySettings: builder.query<CompanySettings, void>({
    query: () => '/company/settings',
    transformResponse(baseQueryReturnValue: ApiCompanySettingsResponse): CompanySettings {
      return translateCompanySettings(baseQueryReturnValue);
    },
    providesTags: () => ['Company'],
  }),
  getCompanyData: builder.query<CompanyData, void>({
    query: () => '/company/details/show',
    transformResponse(baseQueryReturnValue: ApiCompanyDetailsResponse): CompanyData {
      return translateCompanyData(baseQueryReturnValue);
    },
    providesTags: () => ['CompanyDetails'],
  }),
  putCompanyDetails: builder.mutation<any, FormCompanyDetails>({
    query: (payload) => ({
      url: '/company/details/change',
      method: 'PUT',
      body: payload,
    }),
    invalidatesTags: () => ['CompanyDetails'],
  }),
  postCompanyAttachments: builder.mutation<any, FormData>({
    query: (payload) => ({
      url: '/company/attachments/upload',
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['CompanyDetails'],
  }),
  putColorPalette: builder.mutation<any, ColorPalette>({
    query: (payload) => ({
      url: '/company/settings/change',
      method: 'PUT',
      body: {
        color_palette: payload,
      },
    }),
    invalidatesTags: () => ['Company', 'UserAccess'],
  }),
  getMailServerConfig: builder.query<MailServerConfig, void>({
    query: () => 'company/mail_server_config',
    transformResponse(baseQueryReturnValue: ApiMailServerConfigResponse) {
      return translateMailServerConfig(baseQueryReturnValue);
    },
    providesTags: () => ['MailServerConfig'],
  }),
  putChangeMailServerConfig: builder.mutation<any, ApiPutMailServerConfig>({
    query: (payload) => ({
      url: 'company/mail_server_config/change',
      method: 'PUT',
      body: payload,
    }),
    invalidatesTags: () => ['MailServerConfig'],
  }),
  postTestMailServerConfig: builder.mutation({
    query: (payload) => ({
      url: 'company/mail_server_config/test',
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['MailServerConfig'],
  }),
});

import * as React from 'react';
import { useEffect, useRef } from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';
import CommentForm from './CommentForm/CommentForm';
import AttachmentPreview from '../AttachmentPreview/AttachmentPreview';
import { TicketComment } from '../../api/modules/tickets/ticketsTypes';
import { useGetFullUserAccountQuery } from '../../api/appApi';
import useMessages from '../../hooks/useMessages';

interface Props {
  ticketId?: string,
  taskId?: string,
  documentId?: string,
  comments?: TicketComment[],
  publicComment: boolean
}

const CommentsList = ({
  ticketId, taskId, documentId, comments, publicComment,
}: Props) => {
  const { data: user } = useGetFullUserAccountQuery();
  const userFullName = user?.fullName;
  const listRef = useRef<HTMLUListElement>(null);
  const getMessage = useMessages();

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView({ block: 'nearest' });
  }, [comments]);

  const hasComments = comments && comments?.length > 0;

  return (
    <div className="w-full">
      <ul
        className={classNames(
          'overflow-y-auto shadow-inner flex flex-col',
          { 'h-[300px] pt-2': hasComments, 'py-6': !hasComments },
        )}
        ref={listRef}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        {comments?.map((comment, index) => {
          const currentUserComment = comment.author === userFullName;
          return (
          // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={classNames('mb-6 text-text-md-medium md:text-text-lg-medium w-fit max-w-2/3', { 'self-end': currentUserComment })} data-testid="commentRow">
              <div className="flex justify-between items-baseline">
                <div className="justify-end mr-6">
                  {currentUserComment ? getMessage('tickets.ticket.comments.me') : comment.author}
                  :
                </div>
                <div className="flex justify-end text-text-sm-medium">
                  {format(new Date(comment.createdAt), 'dd-MM-yyyy / HH:mm')}
                </div>
              </div>
              <div
                className={classNames(
                  'text-left bg-background-light py-2 px-4 rounded-md whitespace-pre-line',
                  { 'bg-typography-black text-typography-white': currentUserComment },
                  currentUserComment ? 'rounded-tr-none' : 'rounded-tl-none',
                )}
              >
                {comment.content}
              </div>
              <div className="col-span-1" />
              {comment.attachments.length > 0 && (
                <ul className={classNames(
                  'flex flex-wrap w-full',
                  { 'justify-end': currentUserComment },
                )}
                >
                  {comment.attachments.map(({
                    filename, path, contentType, description,
                  }) => (
                    <AttachmentPreview
                      filename={filename}
                      path={path}
                      contentType={contentType}
                      description={description}
                      key={path}
                      className="mr-0"
                    />
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      {!hasComments && (<div>{getMessage('tickets.ticket.comments.noComments')}</div>)}
      <CommentForm ticketId={ticketId} taskId={taskId} documentId={documentId} publicComment={publicComment} />
    </div>
  );
};
export default CommentsList;

import * as React from 'react';
import {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import classNames from 'classnames';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  FullTask, TaskCategory, TaskStatus,
} from '../../../../api/modules/tasks/tasksTypes';
import {
  useGetEmployeesQuery,
  useGetTicketQuery,
  useGetTaskCategoriesQuery,
  useGetTaskStatusesQuery,
  usePostChangeTaskMutation, useGetDocumentQuery,
} from '../../../../api/appApi';
import { InputLabel, InputError } from '../../../../components/FormInputs/InputLabel';
import { Select } from '../../../../components/FormInputs/inputs/FormSelect';
import useMessages, { useMonthsAndWeekDays } from '../../../../hooks/useMessages';
import SearchableDropdown from '../../../../components/SearchableDropdown/SearchableDropdown';
import capitalize from '../../../../../utils/formatters';
import { inputDefaultStyles } from '../../../../components/FormInputs/inputs/FormInput';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';
import { EmployeeShowTicketData } from '../../../../api/modules/tickets/ticketsTypes';
import ConnectedTicketSection from './ConnectedTicketSection';
import ConnectedDocumentSection from './ConnectedDocumentSection';

interface Props {
  task: FullTask,
}

// eslint-disable-next-line max-len
const isFetchBaseQueryError = (error: SerializedError | FetchBaseQueryError): error is FetchBaseQueryError => (error as FetchBaseQueryError).status !== undefined;

const isDateValid = (value: string) => {
  const strings = value.split('-');
  const numbers = strings.map(Number);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [day, month, year] = numbers;

  if (value && numbers.some((number) => Number.isNaN(number))) {
    return false; // in case user enter something other than digits
  }

  if (month > 12 || month < 0) return false;
  if (day < 0 || day > 31) return false;
  if (year < 0) return false;
  if (strings.some((val: string) => val.startsWith('00'))) return false;

  return true;
};

const TaskInfoCard = ({ task }: Props) => {
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [finishDate, setFinishDate] = useState<null | Date>(null);
  const [realizerState, setRealizerState] = useState<string | null>(null);
  const [backendErrors, setBackendErrors] = useState({ errors: [] as string[], isDateError: false });

  const getMessage = useMessages();
  const { months, weekDays } = useMonthsAndWeekDays();

  const { data: ticketData } = useGetTicketQuery(task.ticketId, { skip: !task.ticketId });
  const { data: document } = useGetDocumentQuery(task.documentId, { skip: !task.documentId });

  const { data: categories } = useGetTaskCategoriesQuery();
  const { data: statuses } = useGetTaskStatusesQuery();
  const [postChange, postChangeResult] = usePostChangeTaskMutation();
  const {
    status, category, realizer, id, startedAt, finishedAt,
  } = task;

  useEffect(() => {
    if (startedAt) setStartDate(new Date(startedAt));
    if (finishedAt) setFinishDate(new Date(finishedAt));
    if (realizer) setRealizerState(realizer);
  }, [startedAt, finishedAt, realizer]);

  const ticket = ticketData as EmployeeShowTicketData;

  const statusSelectOptions = useMemo(() => (statuses || []).map((status: TaskStatus) => ({
    value: status.id,
    optionLabel: capitalize(status.name),
  })), [statuses]);

  const categoryOptionsValues = useMemo(() => categories?.map((category: TaskCategory) => ({
    value: category.id,
    optionLabel: capitalize(category.name),
  })), [categories]);

  const changeParam = useCallback((paramName: string, paramValue: string | null): void => {
    postChange({
      tasks: {
        [id]: {
          [paramName]: paramValue,
        },
      },
    });
  }, [id]);

  useEffect(() => {
    if (postChangeResult.status === 'fulfilled') {
      setBackendErrors({
        errors: [],
        isDateError: false,
      });
    } else if (postChangeResult.status === 'rejected') {
      /* @ts-ignore */
      const errors = postChangeResult.error.data?.errors || [];
      const errorsArray = Array.isArray(errors) ? errors : [errors];
      setBackendErrors({
        errors: errorsArray,
        isDateError: errorsArray.filter((error) => error.includes('Data')).length > 0,
      });
    }
  }, [postChangeResult]);

  return (
    <>
      <section className="border-b pb-4 tablet:flex tablet:w-full tablet:mb-4">
        {postChangeResult.isError && isFetchBaseQueryError(postChangeResult.error) && (typeof (postChangeResult.error.status) !== 'number') && (
          <FormNotice type="error" message={getMessage('form.error')} />
        )}
        <div className="tablet:mr-5 tablet:w-1/5">
          <InputLabel
            label={getMessage('tasks.task.status')}
          />
          <Select
            selectOptions={statusSelectOptions}
            onChange={(id) => changeParam('status_id', id)}
            value={status.id}
            inputClassName="mb-4"
          />
        </div>
        <div className="tablet:mr-5 tablet:w-1/5">
          <InputLabel
            label={getMessage('tasks.task.category')}
          />
          {categoryOptionsValues && (
            <Select
              selectOptions={categoryOptionsValues}
              onChange={(id) => changeParam('category_id', id)}
              value={category.id}
            />
          )}
        </div>
        <div className="tablet:mr-5 tablet:w-1/5">
          <InputLabel
            label={getMessage('tasks.task.realizer')}
            showReset={!!realizerState}
            resetButtonText={getMessage('remove')}
            onReset={() => {
              changeParam('realizer_id', null);
              setRealizerState(null);
            }}
          />
          <SearchableDropdown
            className="w-full"
            query={useGetEmployeesQuery}
            onSelect={(id) => changeParam('realizer_id', id)}
            initialState={realizerState}
            ariaLabel={getMessage('form.ariaLabel.realizer')}
          />
        </div>
        <div className="tablet:mr-5 tablet:w-1/5">
          <InputLabel
            label={getMessage('tasks.task.startedAt')}
            id="started_at"
            showReset={!!startDate}
            resetButtonText={getMessage('remove')}
            onReset={() => {
              setStartDate(null);
              changeParam('started_at', null);
            }}
          />
          <DatePicker
            id="started_at"
            value={startDate || null}
            months={months}
            weekDays={weekDays}
            weekStartDayIndex={1}
            format="DD-MM-YYYY"
            inputClass={classNames(
              'w-full',
              inputDefaultStyles,
              { 'border-interactions-input-error border-2': backendErrors.isDateError },
            )}
            className="red"
            containerClassName="w-full"
            onOpenPickNewDate={false}
            onChange={(
              date: DateObject,
              { input, isTyping }: { input: HTMLInputElement, isTyping: boolean },
            ) => {
              if (!isTyping) {
                if (input.value.length === 0) {
                  // user clears input using keyboard
                  setStartDate(null);
                  changeParam('started_at', null);
                } else {
                  // user selects the date from the calendar
                  return changeParam('started_at', date?.format('YYYY-MM-DD'));
                }
              }

              if (isDateValid(input.value)) {
                return changeParam('started_at', date?.format('YYYY-MM-DD'));
              }
              return false;
            }}
          />
        </div>
        <div className="tablet:mr-5 tablet:w-1/5">
          <InputLabel
            label={getMessage('tasks.task.finishedAt')}
            id="finished_at"
            showReset={!!finishDate}
            resetButtonText={getMessage('remove')}
            onReset={() => {
              setFinishDate(null);
              changeParam('finished_at', null);
            }}
          />
          <DatePicker
            id="finished_at"
            value={finishDate || null}
            months={months}
            weekDays={weekDays}
            weekStartDayIndex={1}
            format="DD-MM-YYYY"
            inputClass={classNames(
              'w-full',
              inputDefaultStyles,
              { 'border-interactions-input-error border-2': backendErrors.isDateError },
            )}
            className="red"
            containerClassName="w-full"
            onOpenPickNewDate={false}
            onChange={(
              date: DateObject,
              { input, isTyping }: { input: HTMLInputElement, isTyping: boolean },
            ) => {
              if (!isTyping) {
                if (input.value.length === 0) {
                  // user clears input using keyboard
                  setFinishDate(null);
                  changeParam('finished_at', null);
                } else {
                  // user selects the date from the calendar
                  return changeParam('finished_at', date?.format('YYYY-MM-DD'));
                }
              }

              if (isDateValid(input.value)) {
                return changeParam('finished_at', date?.format('YYYY-MM-DD'));
              }
              return false;
            }}
          />
          { /* @ts-ignore */}
          {backendErrors && backendErrors.errors?.map((item) => <InputError message={item} />)}
        </div>
      </section>
      {ticket && <ConnectedTicketSection task={task} ticket={ticket} />}
      {document && <ConnectedDocumentSection task={task} document={document} />}
    </>
  );
};

export default TaskInfoCard;

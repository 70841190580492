/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useMessages from '../../../../hooks/useMessages';
import { usePostTicketMutation, useGetFullUserAccountQuery, useGetTicketCategoriesQuery } from '../../../../api/appApi';
import {
  FormInput, FormSelect, FormTextarea, FormFileInput,
} from '../../../../components/FormInputs';
import Button from '../../../../components/Button/Button';
import FileUploadPreview from '../../../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import { translateTicketsToFormData } from '../../../../api/modules/tickets/ticketsTranslator';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import capitalize from '../../../../../utils/formatters';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';

type TicketAttachment = {
  file: FileList | undefined,
  description: string
};

type SelectVenue = {
  value: string,
  optionLabel: string,
};

export type TicketInputs = {
  title: string,
  venueId: string,
  category_id: string,
  description: string,
  attachments: TicketAttachment[]
};

const TenantTicketForm = () => {
  const [venueSelectOptions, setVenueSelectOptions] = useState<SelectVenue[]>([{
    value: '',
    optionLabel: '',
  }]);
  const getMessage = useMessages();
  const navigate = useNavigate();
  const [postTicketData, result] = usePostTicketMutation();
  const { selectedVenueId, selectedVenueName } = useAppSelector((state) => state.app);
  const { data: user, isSuccess: userFetched } = useGetFullUserAccountQuery();
  const { data: ticketCategories } = useGetTicketCategoriesQuery();

  const venues = useMemo(() => user?.tenant?.venues, [user]);

  useEffect(() => {
    if (venues !== undefined && venues?.length > 0) {
      if (selectedVenueName === getMessage('navigation.allVenues')) {
        const venueSelectOptions = venues?.map((venue) => ({
          value: venue.id as string,
          optionLabel: venue.address,
        }));
        setVenueSelectOptions(venueSelectOptions);
      } else {
        setVenueSelectOptions([{
          value: selectedVenueId as string,
          optionLabel: selectedVenueName as string,
        }]);
      }
    }
  }, [selectedVenueId, selectedVenueName]);

  const methods = useForm<TicketInputs>({
    defaultValues: {
      attachments: [{ description: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });

  const attachments = methods.watch('attachments');
  const allAttachmentsSelected = attachments.every((attachment) => attachment.file?.length);

  useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  const onSubmit = (data: TicketInputs) => {
    postTicketData({
      ticket: translateTicketsToFormData(data),
    });
  };

  useEffect(() => {
    if (result.status === 'fulfilled') navigate('/zgloszenia');
  }, [result.status]);

  const categorySelectOptions = ticketCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  }));

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      {result.error && <FormNotice type="error" message={result.error.data?.errors || getMessage('form.error')} />}
      <form onSubmit={methods.handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-12">
        <section className="col-span-12 md:col-span-6">
          {categorySelectOptions && (
          <FormSelect
            className="mt-4"
            id="category_id"
            label={getMessage('tickets.ticket.category')}
            inline
            selectOptions={categorySelectOptions}
          />
          )}
          {userFetched && (
          <FormSelect
            className="mt-4"
            id="venue_id"
            label={getMessage('tickets.ticket.venue')}
            inline
            selectOptions={venueSelectOptions}
          />
          )}
          <FormInput
            className="mt-4 w-full"
            inputClassName="w-full"
            id="title"
            type="text"
            label={getMessage('tickets.ticket.title')}
            options={{ required: getMessage('tickets.ticket.error.title.required'), maxLength: { value: 150, message: getMessage('tickets.ticket.error.title.maxLength') } }}
          />
          <FormTextarea
            className="mt-6 w-full"
            inputClassName="w-full"
            id="description"
            label={getMessage('tickets.ticket.description')}
          />
          {fields.map(({ file, id }, index) => {
            const fileSelected = file && file.length > 0;
            return (
              <div key={id} id={id} className="flex align-start mb-4 w-full">
                {fileSelected && (
                <FileUploadPreview attachment={attachments[index]} onDelete={() => remove(index)} />
                )}
                <div className="w-full">
                  {fileSelected && (
                  <FormInput
                    className="w-full"
                    inputClassName="text-xs w-full"
                    id={`attachments.${index}.description`}
                    type="text"
                    placeholder={getMessage('tickets.ticket.addAttachmentDescription')}
                  />
                  )}
                  <FormFileInput
                    inputClassName={fileSelected ? 'hidden' : ''}
                    id={`attachments.${index}.file`}
                    labelText={getMessage('tickets.ticket.addAttachment')}
                    labelClassName={fileSelected ? 'hidden' : ''}
                    withIcon
                  />
                </div>
              </div>
            );
          })}
        </section>
        <div className="col-span-12 col-start-1">
          <Button type="submit" className="mt-6">{getMessage('save')}</Button>
        </div>
      </form>
    </FormProvider>
  );
};
export default TenantTicketForm;

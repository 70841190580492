import * as React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import TenantMetersPage from './pages/LocalPage/TenantMetersPage/TenantMetersPage';
import MetersVenuesListPage from './pages/LocalPage/EmployeeMeters/MetersVenuesListPage/MetersVenuesListPage';
import EmployeeMetersListPage from './pages/LocalPage/EmployeeMeters/EmployeeMetersListPage/EmployeeMetersListPage';
import PeopleCountPage from './pages/PeopleCountPage/PeopleCountPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import TicketsPage from './pages/TicketsPage/TicketsPage';
import TicketPage from './pages/TicketsPage/TicketPage/TicketPage';
import TicketForm from './pages/TicketsPage/TicketForm/TicketForm';
import UserPermissionsPage from './pages/UsersPermissionsPage/UserPermissionsPage';
import RolesPage from './pages/RolesPage/RolesPage';
import ResolutionsPage from './pages/ResolutionsPage/ResolutionsPage';
import ResolutionPage from './pages/ResolutionsPage/ResolutionPage/ResolutionPage';
import ResolutionAttachmentPreview from './pages/ResolutionsPage/ResolutionAttachmentPreview/ResolutionAttachmentPreview';
import AnnouncementsPage from './pages/AnnouncementsPage/AnnouncementsPage';
import AnnouncementPage from './pages/AnnouncementsPage/AnnouncementPage/AnnouncementPage';
import AnnouncementForm from './pages/AnnouncementsPage/AnnouncementForm/AnnouncementForm';
import AnnouncementChangePage from './pages/AnnouncementsPage/AnnouncementChangePage/AnnouncementChangePage';
import SettlementsPage from './pages/SettlementsPage/SettlementsPage';
import TasksPage from './pages/TasksPage/TasksPage';
import TaskFormPage from './pages/TasksPage/TaskFormPage/TaskFormPage';
import TaskPage from './pages/TasksPage/TaskPage/TaskPage';
import UsersPage from './pages/UsersPage/UsersPage';
import NewUserPage from './pages/UsersPage/NewUserPage/NewUserPage';
import TasksParametersPage from './pages/ParametersPage/TasksParametersPage/TasksParametersPage';
import TicketsParametersPage from './pages/ParametersPage/TicketsParametersPage/TicketsParametersPage';
import SettlementsVenueListPage from './pages/SettlementsPage/SettlementsVenueListPage/SettlementsVenueListPage';
import UserPage from './pages/UsersPage/UserPage/UserPage';
import CompanyDetailsPage from './pages/SettingsPage/SettingsPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import StatusesPage from './pages/ParametersPage/StatusesPage/StatusesPage';
import StatusEditPage from './pages/ParametersPage/StatusEditPage/StatusEditPage';
import JournalsPage from './pages/ParametersPage/JournalsPage/JournalsPage';
import JournalPage from './pages/ParametersPage/JournalEditPage/JournalEditPage';
import JournalFormPage from './pages/ParametersPage/JournalFormPage/JournalFormPage';
import DocumentsPage from './pages/DocumentsPage/DocumentsPage';
import DocumentFormPage from './pages/DocumentsPage/DocumentForm/DocumentFormPage';
import DocumentPage from './pages/DocumentsPage/DocumentPage';
import DocumentsParametersPage from './pages/ParametersPage/DocumentsParametersPage/DocumentsParametersPage';
import PasswordChangeRequiredPage from './pages/PasswordChangeRequiredPage/PasswordChangeRequiredPage';
import { appApi } from './api/appApi';
import { useAppDispatch } from './hooks/reduxHooks';

export const routes = [
  {
    path: '/',
    element: (
      <DashboardPage />
    ),
  },
  {
    path: '/liczniki/historia',
    element: (
      <TenantMetersPage tab="history" />
    ),
  },
  {
    path: '/liczniki',
    element: (
      <TenantMetersPage tab="list" />
    ),
  },
  {
    path: '/liczniki/lokale',
    element: (
      <MetersVenuesListPage />
    ),
  },
  {
    path: '/liczniki/lokale/:id/do-odczytu',
    element: (
      <EmployeeMetersListPage />
    ),
  },
  {
    path: '/liczniki/lokale/:id',
    element: (
      <EmployeeMetersListPage />
    ),
  },
  {
    path: '/liczba-osob',
    element: (
      <PeopleCountPage />
    ),
  },
  {
    path: '/aktualnosci',
    element: (
      <AnnouncementsPage />
    ),
  },
  {
    path: '/aktualnosci/:id',
    element: (
      <AnnouncementPage />
    ),
  },
  {
    path: '/aktualnosci/:id/edytuj',
    element: (
      <AnnouncementChangePage />
    ),
  },
  {
    path: '/aktualnosci/archiwum',
    element: (
      <AnnouncementsPage archived />
    ),
  },
  {
    path: '/aktualnosci/nowe',
    element: (
      <AnnouncementForm />
    ),
  },
  {
    path: '/dokumenty',
    element: (
      <DocumentsPage />
    ),
  },
  {
    path: '/dokumenty/nowy',
    element: (
      <DocumentFormPage />
    ),
  },
  {
    path: '/dokumenty/:id',
    element: (
      <DocumentPage />
    ),
  },
  {
    path: '/dokumenty/nowe-zadanie/:id',
    element: (
      <TaskFormPage taskType="document" />
    ),
  },
  {
    path: '/zgloszenia',
    element: (
      <TicketsPage />
    ),
  },
  {
    path: '/zgloszenia/moje',
    element: (
      <TicketsPage tab="me" />
    ),
  },
  {
    path: '/zgloszenia/nieprzypisane',
    element: (
      <TicketsPage tab="none" />
    ),
  },
  {
    path: '/zgloszenia/:id',
    element: (
      <TicketPage />
    ),
  },
  {
    path: '/zgloszenia/nowe',
    element: (
      <TicketForm />
    ),
  },
  {
    path: '/zgloszenia/nowe-zadanie/:id',
    element: (
      <TaskFormPage taskType="ticket" />
    ),
  },
  {
    path: '/zadania/',
    element: (
      <TasksPage />
    ),
  },
  {
    path: '/zadania/nieprzypisane',
    element: (
      <TasksPage tab="unassigned" />
    ),
  },
  {
    path: '/zadania/otwarte',
    element: (
      <TasksPage tab="open" />
    ),
  },
  {
    path: '/zadania/:id',
    element: (
      <TaskPage />
    ),
  },
  {
    path: '/profil/wyglad',
    element: (
      <ProfilePage tab="settings" />
    ),
  },
  {
    path: '/profil/rejestr_zdarzen',
    element: (
      <ProfilePage tab="activityLog" />
    ),
  },
  {
    path: '/profil',
    element: (
      <ProfilePage tab="profile" />
    ),
  },
  {
    path: '/profil/zmien_haslo',
    element: (
      <PasswordChangeRequiredPage />
    ),
  },
  {
    path: '/uchwaly',
    element: (
      <ResolutionsPage />
    ),
  },
  {
    path: '/uchwaly/aktywne',
    element: (
      <ResolutionsPage active="true" />
    ),
  },
  {
    path: '/uchwaly/zamkniete',
    element: (
      <ResolutionsPage active="false" />
    ),
  },
  {
    path: '/uchwaly/:id',
    element: (
      <ResolutionPage />
    ),
  },
  {
    path: '/uchwaly/:id/akceptuj/:index',
    element: (
      <ResolutionAttachmentPreview />
    ),
  },
  {
    path: '/uzytkownicy',
    element: (
      <UsersPage tab="all" />
    ),
  },
  {
    path: '/uzytkownicy/pracownicy',
    element: (
      <UsersPage tab="workers" />
    ),
  },
  {
    path: '/uzytkownicy/nowy',
    element: (
      <NewUserPage />
    ),
  },
  {
    path: '/uzytkownicy/:id',
    element: (
      <UserPage />
    ),
  },
  {
    path: '/uzytkownicy/rejestr_zdarzen',
    element: (
      <UsersPage tab="activityLog" />
    ),
  },
  {
    path: '/uprawnienia/role/nowa_rola',
    element: (
      <RolesPage newRole />
    ),
  },
  {
    path: '/uprawnienia/role/:id?',
    element: (
      <RolesPage />
    ),
  },
  {
    path: '/uprawnienia/:id',
    element: (
      <UserPermissionsPage />
    ),
  },
  {
    path: '/rozrachunki/lokale',
    element: (
      <SettlementsVenueListPage tab="all" />
    ),
  },
  {
    path: '/rozrachunki/lokale/aktywne',
    element: (
      <SettlementsVenueListPage tab="active" />
    ),
  },
  {
    path: '/rozrachunki/platnosci',
    element: (
      <SettlementsPage tab="payments" />
    ),
  },
  {
    path: '/rozrachunki/historia',
    element: (
      <SettlementsPage tab="history" />
    ),
  },
  {
    path: '/rozrachunki/analiza_salda',
    element: (
      <SettlementsPage tab="balance" />
    ),
  },
  {
    path: '/rozrachunki/analiza_salda/:year',
    element: (
      <SettlementsPage tab="balance" />
    ),
  },
  {
    path: '/rozrachunki/analiza_salda/:year/:month',
    element: (
      <SettlementsPage tab="balance" />
    ),
  },
  {
    path: '/rozrachunki/analiza_salda/:year/:month/:titleId',
    element: (
      <SettlementsPage tab="balance" />
    ),
  },
  {
    path: '/parametry/statusy',
    element: (
      <StatusesPage />
    ),
  },
  {
    path: '/parametry/statusy/:id',
    element: (
      <StatusEditPage />
    ),
  },
  {
    path: '/parametry/dzienniki',
    element: (
      <JournalsPage />
    ),
  },
  {
    path: '/parametry/dzienniki/nowy',
    element: (
      <JournalFormPage />
    ),
  },
  {
    path: '/parametry/dzienniki/:id',
    element: (
      <JournalPage />
    ),
  },
  {
    path: '/parametry/zadania',
    element: (
      <TasksParametersPage />
    ),
  },
  {
    path: '/parametry/zgloszenia',
    element: (
      <TicketsParametersPage />
    ),
  },
  {
    path: '/parametry/dokumenty',
    element: (
      <DocumentsParametersPage />
    ),
  },
  {
    path: '/ustawienia/dane',
    element: (
      <CompanyDetailsPage tab="companyDetails" />
    ),
  },
  {
    path: '/ustawienia/poczta',
    element: (
      <CompanyDetailsPage tab="MailServerConfig" />
    ),
  },
];

const router = createBrowserRouter(routes);

const AppRouter = () => {
  const dispatch = useAppDispatch();

  const onFocus = () => {
    dispatch(appApi.endpoints.getFullUserAccount.initiate(undefined, { forceRefetch: true }));
  };
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  return (
    <RouterProvider router={router} />
  );
};

export default AppRouter;

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import {
  translateApiTasks,
  translateFullApiTask,
  translateFullTaskStatus,
  translateTaskStatus,
} from './tasksTranslator';
import {
  ApiTasksResponse,
  TaskColumnId,
  ApiColumnsSettings,
  ApiPostColumnsSettings,
  Tasks,
  ApiTaskCategories,
  ApiTaskResponse,
  FullTask,
  ApiPostChangeTaskData,
  ApiPostTaskComment,
  TaskCategory,
  TaskStatus,
  ApiTaskStatuses,
  FullTaskStatus,
} from './tasksTypes';

export const tasksQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getTasks: builder.query<Tasks, Record<string, string | string[]>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `tasks/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiTasksResponse): Tasks {
      return translateApiTasks(baseQueryReturnValue);
    },
    providesTags: () => ['Tasks'],
  }),
  getTask: builder.query<FullTask, string>({
    query: (id) => `tasks/${id}/show`,
    transformResponse(baseQueryReturnValue: ApiTaskResponse): FullTask {
      return translateFullApiTask(baseQueryReturnValue.task);
    },
    providesTags: () => ['Task'],
  }),
  postTaskComment: builder.mutation<any, ApiPostTaskComment>({
    query: ({ id, payload }) => ({
      url: `/tasks/${id}/add_comment`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['Task', 'Tasks'],
  }),
  getTaskCategories: builder.query<TaskCategory[], void>({
    query: () => '/task_categories/list',
    transformResponse(baseQueryReturnValue: ApiTaskCategories) {
      return baseQueryReturnValue.task_categories;
    },
    providesTags: () => ['TasksCategories'],
  }),
  getSelectedTasksColumns: builder.query<TaskColumnId[], void>({
    query: () => '/tasks/list/columns_settings',
    transformResponse(baseQueryReturnValue: ApiColumnsSettings): TaskColumnId[] {
      return baseQueryReturnValue.selected_columns;
    },
    providesTags: () => ['ColumnSettings'],
  }),
  postSelectedTasksColumns: builder.mutation<any, ApiPostColumnsSettings>({
    query: (body) => ({
      url: '/tasks/list/change_columns_settings',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => ['ColumnSettings', 'Tasks'],
  }),
  postChangeTask: builder.mutation<any, ApiPostChangeTaskData>({
    query: (tasks) => ({
      url: '/tasks/change',
      method: 'POST',
      body: tasks,
    }),
    invalidatesTags: () => ['Tasks', 'Task', 'ActivityLog'],
  }),
  postTaskCategory: builder.mutation<any, { name: string }>({
    query: (body) => ({
      url: '/task_categories/add',
      method: 'POST',
      body,
    }),
    invalidatesTags: ['TasksCategories'],
  }),
  patchTaskCategory: builder.mutation<any, { name: string, id: string }>({
    query: ({ id, name }) => ({
      url: `/task_categories/${id}/change`,
      method: 'PATCH',
      body: { name },
    }),
    invalidatesTags: ['TasksCategories'],
  }),
  deleteTaskCategory: builder.mutation<any, { id: string }>({
    query: ({ id }) => ({
      url: `/task_categories/${id}/remove`,
      method: 'DELETE',
    }),
    invalidatesTags: ['TasksCategories'],
  }),
  getTaskStatuses: builder.query<TaskStatus[], void>({
    query: () => 'tickets/statuses/task/list',
    transformResponse(baseQueryReturnValue: ApiTaskStatuses) {
      return translateTaskStatus(baseQueryReturnValue).statuses;
    },
    providesTags: ['TasksStatuses'],
  }),
  getFullTaskStatuses: builder.query<FullTaskStatus[], void>({
    query: () => 'tickets/statuses/task/list',
    transformResponse(baseQueryReturnValue: ApiTaskStatuses) {
      return translateFullTaskStatus(baseQueryReturnValue);
    },
    providesTags: ['TasksStatuses'],
  }),
});

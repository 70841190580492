import * as React from 'react';
import { useMemo } from 'react';
import { usePermissions } from '../../../hooks/usePermissions';
import { useGetFullUserAccountQuery } from '../../../api/appApi';
import YearsBalanceHeader from './YearsBalanceHeader/YearsBalanceHeader';
import Tabs, { TabElement } from '../../../components/Tabs/Tabs';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import YearsBalanceTable from './YearsBalanceTable/YearsBalanceTable';
import ExpandableCard from '../../../components/ExpandableCard/ExpandableCard';
import { useSettlementsVenue } from '../../../hooks/settlements/useSettlementsVenue';

interface Props {
  year?: string,
  tabs: TabElement[],
}

const YearsBalancePage = ({ year, tabs }: Props) => {
  const { checkPermission, checkModuleEnabled } = usePermissions();
  const { data: user } = useGetFullUserAccountQuery();

  const settlementVenue = useSettlementsVenue();
  const usersVenues = useMemo(() => user?.tenant?.venues || [], [user]);

  checkModuleEnabled('settlements');
  checkPermission('settlements_balance_list');

  return (
    <>
      <YearsBalanceHeader year={year} />
      <Tabs tabElements={tabs} />
      <ContentWrapper>
        {settlementVenue.venueId ? (
          <YearsBalanceTable year={year} venue={settlementVenue} user={user} />
        ) : (
          <>
            {usersVenues.map((venue) => (
              <ExpandableCard className="col-span-12" title={venue.address} key={venue.id}>
                <YearsBalanceTable
                  year={year}
                  venue={{ venueAddress: venue.address, venueId: venue.id }}
                  user={user}
                />
              </ExpandableCard>
            ))}
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export default YearsBalancePage;

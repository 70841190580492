import { PaginationMetadata } from '../../paginationTypes';

export interface ApiTasksResponse {
  tasks: ApiTask[],
  metadata: PaginationMetadata,
}

export interface ApiTaskResponse {
  task: FullApiTask,
}

export interface Tasks {
  tasks: Task[],
  metadata: PaginationMetadata,
}

export type ApiTask = {
  id: string,
  status: string,
  created_at: string,
  last_comment_at: string,
  task_number: string,
  title: string,
  venue_address: string,
  venue_code: string,
  homeowner_association_code: string,
  homeowner_association_name: string,
  comments: ApiTaskComment[],
  category: string,
  creator: string,
  creator_external_id: string,
  realizer: string,
  realizer_external_id: string,
  estimated_start_time: string,
  estimated_finish_time: string,
  started_at: string,
  finished_at: string,
};

export interface ApiTaskComment {
  attachments: ApiAttachment[]
  author: string,
  content: string,
  created_at: string,
  public: boolean,
}

export interface ApiAttachment {
  description: string,
  filename: string,
  path: string,
  content_type: string,
}

export type FullApiTask = Omit<ApiTask, 'status' | 'category'> & {
  attachments: ApiAttachment[],
  category: TaskCategory,
  comments: ApiTaskComment[]
  created_at: string,
  description: string,
  status: ApiTaskStatus,
  ticket: {
    id: string,
  },
  document: {
    id: string,
  }
};

export type Task = {
  id: string,
  status: string,
  createdAt: string,
  lastCommentAt: string,
  taskNumber: string,
  title: string,
  venueAddress: string,
  venueCode: string,
  homeownerAssociationCode: string,
  homeownerAssociationName: string,
  category: string,
  creator: string,
  creatorCode: string,
  realizer: string,
  realizerCode: string,
  estimatedStartTime: string,
  estimatedFinishTime: string,
  startedAt: string,
  finishedAt: string,
};

export type FullTask = Omit<Task, 'status' | 'category'> & {
  description: string,
  attachments: TaskAttachment[],
  ticketId?: string,
  comments: TaskComment[],
  status: TaskStatus,
  category: TaskCategory,
  documentId?: string,
};

export type TaskComment = {
  author: string,
  content: string,
  createdAt: string,
  attachments: TaskAttachment[]
};

export type TaskAttachment = {
  description: string,
  filename: string,
  path: string,
  contentType: string,
};

export type ApiPostTask = {
  task: FormData,
  id: string,
};

export type Attachment = {
  file: FileList | undefined,
  description: string
};

export type TaskCategory = {
  id: string,
  name: string,
};

export type ApiTaskCategories = {
  task_categories: TaskCategory[]
};

export type ApiTaskStatus = {
  id: string,
  object_type: string,
  name: string,
  active: boolean,
  internal_name: string,
  external_name: string,
  description: string,
};

export interface FullTaskStatus {
  id: string,
  objectType: string,
  name: 'open' | 'completed' | string,
  active: boolean,
  internalName: string,
  externalName: string,
  description: string,
}

export type ApiTaskStatuses = {
  statuses: ApiTaskStatus[]
};

export type TaskStatus = TaskCategory;

export interface TaskStatuses {
  statuses: TaskStatus[]
}

export interface ApiPostTaskComment {
  id: string,
  payload: FormData
}

export const taskDefaultColumnIds = [
  'task_number', 'title', 'status', 'created_at', 'last_comment_at',
  'venue_address', 'homeowner_association_code', 'category', 'realizer',
];
export const taskCustomizableColumnIds = [
  'venue_code', 'homeowner_association_name', 'creator_external_id', 'creator',
  'estimated_start_time', 'started_at', 'estimated_finish_time', 'finished_at',
  'realizer_external_id',
];

export type TaskColumnId = typeof taskCustomizableColumnIds[number] | typeof taskCustomizableColumnIds[number];

export interface ApiPostColumnsSettings {
  columns?: (TaskColumnId | undefined)[]
}

export interface ApiColumnsSettings {
  selected_columns: TaskColumnId[]
}

export interface TaskInputs {
  title: string,
  ticket_id?: string,
  document_id?: string,
  category_id: string,
  realizer_id: string,
  status_id: string,
  description: string,
  attachments: Attachment[],
  estimated_start_time: string,
  estimated_finish_time: string,
  started_at: string,
  finished_at: string,
  tenant: string,
  venue: string,
  homeownerAssociation: string,
}

export interface ApiPostChangeTaskData {
  tasks: {
    [id: string]: {
      status_id?: string,
      category_id?: string
      realizer_id?: string | null,
    }
  }
}

import { FetchRequest } from '@rails/request.js';
import { stringToBase64 } from './formatters';

const logInForm = document.getElementById('accounts_sessions_new_form') as HTMLFormElement;

function createAlert(msg: string) {
  const existingElement = document.getElementById('alert');

  if (existingElement) {
    existingElement.style.display = 'block';
    // @ts-ignore
    existingElement.children[1].innerText = msg;
  } else {
    const alertDiv = document.createElement('div');
    const errorSpan = document.createElement('span');
    const errorMsgSpan = document.createElement('span');
    const errorMsg = document.createTextNode(msg);

    alertDiv.id = 'alert';
    alertDiv.className = 'alert bg-interactions-error-bg text-interactions-error-text';
    errorSpan.className = 'font-semibold block';

    alertDiv.appendChild(errorSpan);
    alertDiv.appendChild(errorMsgSpan);

    errorSpan.appendChild(document.createTextNode('Bład'));
    errorMsgSpan.appendChild(errorMsg);

    document.body.appendChild(alertDiv);
  }
}

const fetchRequest = async (form: HTMLFormElement, payload: {}) => {
  const method = (form.elements.namedItem('_method') as HTMLInputElement)?.value || form.method;
  const request = new FetchRequest(method, form.action, {
    body: JSON.stringify({
      authenticity_token: (form.elements.namedItem('authenticity_token') as HTMLInputElement).value,
      commit: (form.elements.namedItem('commit') as HTMLInputElement).value,
      ...payload,
    }),
  });
  const response = await request.perform();

  if (response.ok) {
    window.location.replace('/');
  } else {
    response.text.then(((text) => createAlert(text)));
  }
};

logInForm?.addEventListener('submit', async (event) => {
  event.preventDefault();
  const form = event.target as HTMLFormElement;

  await fetchRequest(form, {
    account: {
      login_option: stringToBase64((form.elements.namedItem('account_login_option') as HTMLInputElement)?.value),
      password: stringToBase64((form.elements.namedItem('account_password') as HTMLInputElement)?.value),
    },
  });
});

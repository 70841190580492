/* eslint-disable react/jsx-props-no-spreading */
import { RegisterOptions, useController, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { InputError, InputLabel } from '../InputLabel';
import Dropdown from '../../Dropdown/Dropdown';

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none focus:border-solid`;

export interface SelectOption {
  value: string,
  optionLabel: string,
}
interface FormSelectProps {
  id: string,
  label?: string,
  className?: string,
  inputClassName?: string,
  inline?: boolean,
  selectOptions: SelectOption[],
  showReset?: boolean,
  placeholder?: string,
  options?: RegisterOptions,
  autofocus?: boolean,
}
interface SelectProps {
  value: string,
  onChange: (s: string) => void,
  selectOptions: SelectOption[],
  inputClassName?: string,
  label?: string,
  placeholder?: string,
  autofocus?: boolean,
}

export const Select = ({
  selectOptions, inputClassName, value, onChange, label, placeholder = '', autofocus,
} : SelectProps) => {
  const selectedOptionLabel = useMemo(
    () => selectOptions?.find((option) => option.value === value)?.optionLabel,
    [value, selectOptions],
  );

  return (
    <Dropdown
      label={selectedOptionLabel || placeholder}
      labelClassName={classnames(inputDefaultStyles, inputClassName, 'w-full inline-flex justify-between')}
      listClassName="bg-white rounded w-full border p-2"
      ariaLabel={label}
      autofocus={autofocus}
    >
      {selectOptions.map((option) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={classnames(
            inputDefaultStyles,
            'w-full hover:text-hover hover:bg-light-200 border-none h-7',
            { 'text-hover bg-light-200': option.optionLabel === selectedOptionLabel },
          )}
          key={option.value}
          onClick={() => onChange(option.value)}
          aria-selected={option.value === value}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onChange(option.value);
          }}
          role="option"
        >
          {option.optionLabel}
        </div>
      ))}
    </Dropdown>
  );
};

const FormSelect = ({
  id, label, className, inputClassName, inline, selectOptions, showReset, placeholder, options, autofocus,
}: FormSelectProps) => {
  const { formState } = useFormContext();
  const { field } = useController({ name: id, rules: options });

  return (
    <div className={classnames('flex-col items-start', className)}>
      {label && (
        <InputLabel
          label={label}
          inline={inline}
          showReset={showReset && field.value}
          onReset={() => field.onChange('')}
        />
      )}
      <Select
        value={field.value}
        onChange={field.onChange}
        selectOptions={selectOptions}
        inputClassName={classnames(
          inputClassName,
          { 'border-interactions-input-error border-2': formState.errors[id] },
        )}
        label={label}
        placeholder={placeholder}
        autofocus={autofocus}
      />
      {formState.errors[id]?.message && <InputError message={formState.errors[id]?.message as string} />}
    </div>
  );
};

export default FormSelect;

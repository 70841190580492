/* eslint-disable quote-props */
const messages = {
  'navigation.header': 'Nazwa Spółdzielni',
  'navigation.dashboard': 'Strona Główna',
  'navigation.settlements': 'Rozrachunki',
  'navigation.settlements.payments': 'Płatności',
  'navigation.settlements.balance': 'Analiza salda',
  'navigation.settlements.history': 'Historia płatności',
  'navigation.local': 'Sprawy Lokalu',
  'navigation.local.meters': 'Liczniki',
  'navigation.local.peopleCount': 'Liczba osób',
  'navigation.announcements': 'Aktualności',
  'navigation.tickets': 'Zgłoszenia',
  'navigation.tasks': 'Zadania',
  'navigation.documents': 'Dokumenty',
  'navigation.resolutions': 'Uchwały',
  'navigation.profile': 'Profil',
  'navigation.users': 'Użytkownicy',
  'navigation.roles': 'Role',
  'navigation.parameters': 'Parametry',
  'navigation.parameters.statuses': 'Statusy',
  'navigation.parameters.journals': 'Dzienniki',
  'navigation.parameters.tickets': 'Zgłoszenia',
  'navigation.parameters.tasks': 'Zadania',
  'navigation.parameters.documents': 'Dokumenty',
  'navigation.allVenues': 'Wszystkie',
  'navigation.settings': 'Ustawienia',
  'navigation.version': 'PRO Mieszkaniec, wersja',
  'navigation.probit': 'Probit sp. z o.o.',

  'tabs.announcements.all': 'Wszystkie',
  'tabs.announcements.documents': 'Dokumenty',
  'tabs.announcements.statements': 'Komunikaty',
  'tabs.announcements.notices': 'Ogłoszenia',
  'tabs.announcements.archived': 'Archiwum',
  'tabs.tickets.mine': 'Moje',
  'tabs.tickets.all': 'Wszystkie',
  'tabs.tickets.unassigned': 'Nieprzypisane',
  'tabs.tickets.open': 'Otwarte',
  'tabs.settings.mailServerConfig': 'Konfiguracja serwera poczty',
  'tabs.settings.companyDetails': 'Dane podmiotu',
  'logout': 'Wyloguj',
  'save': 'Zapisz',
  'saved': 'Zapisany',
  'send': 'Wyślij',
  'cancel': 'Anuluj',
  'confirm': 'Potwierdzam',
  'open': 'Otwórz',
  'close': 'Zamknij',
  'remove': 'Usuń',
  'goBack': 'Powrót',
  'filters': 'Filtry',
  'reset': 'Zresetuj',
  'choose': 'Wybierz',
  'currency': 'zł',
  'noData': 'Brak danych',
  'noSearchResults': 'Brak wyników spełniających kryteria wyszukiwania',
  'filters.clean': 'Wyczyść',
  'filters.choose': 'Wybierz',
  'filters.search': 'Szukaj...',
  'filters.noResults': 'Brak wyników spełniających kryteria wyszukiwania',
  'table.customize': 'Konfiguruj',
  'sendResponse': 'Wyślij odpowiedź',
  'opensInNewTab': 'załącznik otwiera się w nowej zakładce',
  'download': 'Pobierz załącznik {filename}',
  'more': 'Zobacz szczegóły',
  'menu.ariaLabel': 'Otwórz menu nawigacji',

  'form.phone.error.pattern': 'Proszę podać prawidłowy numer telefonu - wyłącznie cyfry',
  'form.email.error.pattern': 'Proszę podać prawidłowy adres email - musi zawierać znak @',
  'form.postalCode.error.pattern': 'Proszę podać kod pocztowy w formacie XX-XXX, np. 02-584',
  'form.success': 'Dane zostały zaktualizowane',
  'form.error': 'Wystąpił błąd i dane nie zostały zapisane. Spróbuj ponownie później',
  'form.ariaLabel.realizer': 'Wyszukaj osobę realizującą',
  'form.ariaLabel.employees': 'Wyszukaj pracowników',
  'form.ariaLabel.hoa': 'Wyszukaj wspólnoty',
  'form.ariaLabel.estates': 'Wyszukaj osiedla',
  'form.ariaLabel.buildings': 'Wyszukaj budynki',
  'form.ariaLabel.tenants': 'Wyszukaj lokatorów',
  'form.ariaLabel.roles': 'Wyszukaj role',
  'form.name.error.tooLong': 'Nazwa jest zbyt długa - może mieć maksymalnie {maxLength} znaki',

  'dashboard.header': 'Witaj, {username}',
  'dashboard.widget.settlements.balance': 'Saldo',
  'dashboard.widget.settlements.debit': 'Zaległości do zapłaty:',
  'dashboard.widget.settlements.credit': 'Nadpłata:',
  'dashboard.widget.resolutions.allResolutions': 'Wszystkie uchwały',
  'dashboard.widget.resolutions.activeVotings': 'Aktywne głosowania',
  'dashboard.widget.resolutions.noActiveVotings': 'Brak aktywnych głosowań',
  'dashboard.widget.meters.display': 'wyświetl',
  'dashboard.widget.meters.measurementRequired': '{metersNumber} {metersNumber, plural, one {licznik} few {liczniki} other {liczników}} do odczytu - ',
  'dashboard.widget.tasks.inProgress': 'Zadania w toku',
  'dashboard.widget.announcements.addAnnouncement': 'Dodaj aktualność +',
  'dashboard.widget.documents.addAnnouncement': 'Dodaj dokument +',

  'settlements.payments': 'Płatności',
  'settlements.payments.header.title': 'Tytuł',
  'settlements.payments.header.bankAccount': 'Nr konta',
  'settlements.payments.header.value': 'Do zapłaty',
  'settlements.payments.header.makePayment': 'Wykonaj płatność',
  'settlements.payments.pay': 'Zapłać',
  'settlements.payment.inProgress': 'Oczekiwanie na realizację płatności',
  'settlements.payment.success': 'Płatność zrealizowana pomyślnie',
  'settlements.payment.error': 'Wystąpił błąd przy realizacji płatności',
  'settlements.payments.make.error': 'Wystąpił nieoczekiwany błąd i nie udało się wykonać płatności. Spróbuj ponownie później lub skontaktuj się z administratorem strony',
  'settlements.payments.paid': 'Zapłacono',
  'settlements.payments.paymentInProgress': 'W toku',
  'settlements.payments.noOnlinePaymeny': 'Brak płatności online',
  'settlements.payment.close': 'Zamknij',
  'settlements.history': 'Historia płatności',
  'settlements.history.column.bankAccount': 'Nr konta',
  'settlements.history.column.title': 'Tytuł',
  'settlements.history.column.date': 'Data',
  'settlements.history.column.amount': 'Zapłacono',
  'settlements.history.column.status': 'Status',
  'settlements.history.column.status.pending': 'W toku',
  'settlements.history.column.status.success': 'Opłacone',
  'settlements.history.column.status.failure': 'Odrzucone',
  'settlements.history.filters.title': 'Tytuł',
  'settlements.history.filters.date': 'Data',
  'settlements.history.filters.minValue': 'Minimalna kwota',
  'settlements.history.filters.maxValue': 'Maksymalna kwota',
  'settlements.history.filters.status': 'Status',
  'settlements.history.noResults': 'Brak historii',

  'settlements.balance': 'Analiza Salda',
  'settlements.balance.month': 'Miesiąc',
  'settlements.balance.debits': 'Obciążenia',
  'settlements.balance.credits': 'Uznania',
  'settlements.balance.balance': 'Saldo',
  'settlements.balance.openingBalance': 'Bilans otwarcia',
  'settlements.balance.sum': 'Razem',
  'settlements.balance.chooseVenue': 'Proszę wybrać lokal',
  'settlements.balance.filters.venue': 'Lokal',
  'settlements.balance.filters.year': 'Rok',
  'settlements.balance.filters.title': 'Tytuł',
  'settlements.balance.filters.month': 'Miesiąc',
  'settlements.monthsBalance.title': 'Tytuł',
  'settlements.monthsBalance.bankAccount': 'Numer konta',
  'settlements.monthsBalance.balance': 'Saldo',
  'settlements.titleBalance.header.operation': 'Operacja',
  'settlements.titleBalance.header.creationDate': 'Data powstania',
  'settlements.titleBalance.header.paymentDate': 'Termin płatności',
  'settlements.titleBalance.header.status': 'Status',
  'settlements.titleBalance.header.status.open': 'Status - nie zaksięgowano',
  'settlements.titleBalance.header.status.notOpened': 'Status - zaksięgowano',
  'settlements.titleBalance.header.deposits': 'Obciążenia',
  'settlements.titleBalance.header.credits': 'Uznania',
  'settlements.titleBalance.header.balance': 'Saldo',
  'settlements.titleBalance.item.header.invoiceNumber': 'Nr faktury',
  'settlements.titleBalance.item.header.read': 'Odczytano',
  'settlements.titleBalance.item.header.code': 'Kod',
  'settlements.titleBalance.item.header.name': 'Nazwa',
  'settlements.titleBalance.item.header.vat': 'Vat',
  'settlements.titleBalance.item.header.count': 'Ilość',
  'settlements.titleBalance.item.header.price': 'Cena',
  'settlements.titleBalance.item.header.value': 'Wartość',
  'settlements.titleBalance.item.header.sum': 'Razem',
  'settlements.titleBalance.noResults': 'W wybranym miesiącu brak dokumentów dla tytułu: {title}',
  'settlements.venuesList.columns.tenantId': 'Id lokatora',
  'settlements.venuesList.columns.name': 'Imię i nazwisko',
  'settlements.venuesList.columns.code': 'Kod lokalu',
  'settlements.venuesList.columns.building': 'Budynek',
  'settlements.venuesList.columns.residencyDates': 'Data zasiedlenia',
  'settlements.venuesList.columns.homeownerAssociation': 'Wspólnota',
  'settlements.venuesList.columns.membership': 'Członkostwo',
  'settlements.venuesList.member': 'Członek',
  'settlements.venuesList.filters.status': 'Status zasiedlenia',
  'settlements.venuesList.filters.status.resident': 'Aktywne',
  'settlements.venuesList.filters.status.notResident': 'Nieaktywne',
  'settlements.venuesList.filters.tenant': 'ID/Imię i nazwisko',
  'settlements.venuesList.filters.venue': 'Kod lokalu/budynek',
  'settlements.venuesList.filters.residencyDate': 'Data zasiedlenia',
  'settlements.venuesList.filters.homeownerAssociation': 'Wspólnota',
  'settlements.venuesList.filters.membership': 'Członkostwo',
  'settlements.venuesList.filters.membership.member': 'Członek',
  'settlements.venuesList.filters.membership.notMember': 'Nie członek',
  'settlements.venuesList.tabs.active': 'Obecne zasiedlenia',
  'settlements.venuesList.tabs.all': 'Wszystkie',

  'local.meters.type.cw': 'Ciepła woda',
  'local.meters.type.zw': 'Zimna woda',
  'local.meters.type.gas': 'Gaz',
  'local.meters.type.prad': 'Prąd',
  'local.meters.type.co': 'Centralne ogrzewanie',
  'local.meters.type.unknown': 'Nieznany typ licznika',
  'local.meters.noMeters': 'Brak liczników do uzupełnienia',
  'local.meters.sent': 'Wysłany',
  'local.meters.unsent': 'Nie przesłano',
  'local.meters.cannotSend': 'Brak możliwości przesłania stanu licznika',
  'local.meters.error.minValue': 'Nie może być ujemny',
  'local.meters.measurementSent': 'Odczyt przesłany',
  'local.meters.measurementSendingError': 'Wystąpił błąd przy przesyłaniu odczytu',
  'local.meters.meters': 'Liczniki',
  'local.meters.readings': 'Odczyty',
  'local.meters.readingsHistory': 'Historia',
  'local.meters.fillBefore': 'Podaj stany do:',
  'local.meters.outdated': 'Czas na podanie odczytu minął',
  'local.meters.meter': 'Licznik',
  'local.meters.newReading': 'Aktualny odczyt',
  'local.meters.previousReading': 'Poprzedni odczyt',
  'local.meters.reading': 'Odczyt',
  'local.meters.timedOut': 'Nie przesłano',
  'local.meters.history': 'Historia liczników',
  'local.meters.location': 'Lokalizacja',
  'local.meters.history.date': 'Data',
  'local.meters.history.value': 'Stan licznika',
  'local.meters.history.source': 'Źródło danych',
  'local.meters.history.noData': 'Brak odczytów',
  'local.meters.filters.type': 'Typ',
  'local.meters.filters.location': 'Lokalizacja',
  'local.tenants.residency.selectVenueWarning': 'Należy wybrać lokal.',
  'local.tenants.residency.currentTenantsNumber': 'Aktualna liczba osób: {currentTenantsNumber}',
  'local.tenants.residency.upcomingChange': 'Najbliższa zmiana od {validFrom}: {tenantsNumber} {tenantsNumber, plural, one {osoba} few {osoby} other {osób}}.',
  'local.tenants.residency.addNew': 'Dodaj nowe zgłoszenie',
  'local.tenants.residency.history': 'Historia zgłoszeń',
  'local.tenants.residency.noHistory': 'Brak historii do wyświetlenia',
  'local.tenants.residency.createdAt': 'Data zgłoszenia',
  'local.tenants.residency.fromWhen': 'Od kiedy',
  'local.tenants.residency.number': 'Liczba',
  'local.tenants.residency.status': 'Status',
  'local.tenants.residency.tenantsNumber': 'Liczba osób',
  'local.tenants.residency.validFrom': 'Od kiedy obowiązuje zmiana',
  'local.tenants.residency.save': 'Zapisz',
  'local.tenants.residency.success': 'Dodano nowe zgłoszenie',
  'local.tenants.residency.error.required': 'Liczba osób jest wymagana',
  'local.tenants.residency.error.minZero': 'Nie może być mniejsza od 0',
  'local.profile.user': 'Użytkownik',
  'local.profile.theme': 'Wygląd',
  'local.profile.activityLog': 'Rejestr zdarzeń',
  'local.tickets.ticketsList': 'Moje zgłoszenia',
  'local.tickets.newTicket': 'Nowe zgłoszenie',

  'meters.venuesList': 'Lista lokali',
  'meters.venuesList.columns.tenantExternalId': 'ID Lokatora',
  'meters.venuesList.columns.name': 'Imię i nazwisko',
  'meters.venuesList.columns.venueCode': 'Kod lokalu',
  'meters.venuesList.columns.building': 'Budynek',
  'meters.venuesList.columns.residencyDates': 'Data zasiedlenia',
  'meters.venuesList.columns.homeownerAssociation': 'Wspólnota',
  'meters.venuesList.filters.status': 'Status',
  'meters.venuesList.filters.status.measurementProvided': 'Odczytano',
  'meters.venuesList.filters.status.measurementRequired': 'Do odczytu',
  'meters.venuesList.filters.tenantNameID': 'ID/Imię i nazwisko',
  'meters.venuesList.filters.venueAddressCode': 'Kod lokalu/budynek',
  'meters.venuesList.filters.homeownerAssoctiation': 'Wspólnota',
  'meters.metersList.info': 'Info',
  'meters.metersList.fullname': 'Imię i nazwisko',
  'meters.metersList.id': 'ID',
  'meters.metersList.building': 'Budynek',
  'meters.metersList.venueCode': 'Kod lokalu',
  'meters.metersList.residencyDates': 'Data zasiedlenia',
  'meters.metersList.homeownerAssociation': 'Wspólnota',
  'meters.metersList.unsent': 'Minął czas na podanie odczytu przez lokatora. Wprowadź zaległy stan licznika.',

  'announcements.newAnnouncement': 'Tworzenie aktualności',
  'announcements.notice.new': 'Tworzenie ogłoszenia',
  'announcements.notice.form.title': 'Tytuł',
  'announcements.notice.form.description': 'Treść',
  'announcements.notice.form.expirationDate': 'Data wygaśnięcia',
  'announcements.notice.form.receiversGroups': 'Grupy adresatów',
  'announcements.notice.form.homeownerAssociations': 'Wspólnoty',
  'announcements.notice.form.employees': 'Pracownicy',
  'announcements.notice.form.estates': 'Osiedla',
  'announcements.notice.form.buildings': 'Budynki',
  'announcements.notice.form.tenants': 'Lokatorzy',
  'announcements.notice.form.roles': 'Role',
  'announcements.notice.form.addGroup': 'Dodaj grupę',
  'announcements.notice.form.error.title.required': 'Tytuł jest wymagany',
  'announcements.notice.form.error.title.maxLength': 'Tytuł nie może być dłuższy niż 150 znaków',
  'announcements.notice.form.error.description.required': 'Treść jest wymagana',

  'announcements.notice.edit': 'Edytuj',
  'announcements.notice.edit.addAdditionalAttachments': 'Dodaj kolejne załączniki',
  'announcements.notice.editNotice': 'Edytuj ogłoszenie',

  'announcements.announcement': 'Aktualność',
  'announcements.announcement.id': 'ID',
  'announcements.announcement.date': 'Data',
  'announcements.announcement.author': 'Autor',
  'announcements.announcement.recipients': 'Odbiorcy',
  'announcements.announcement.description': 'Treść',
  'announcements.announcement.title': 'Tytuł',
  'announcements.announcement.placeholder': ' ',
  'announcements.announcement.category': 'Kategoria',
  'announcements.announcement.category.document': 'Dokument',
  'announcements.announcement.category.statement': 'Komunikat',
  'announcements.announcement.category.notice': 'Ogłoszenie',
  'announcements.announcement.status.read': 'Przeczytane',
  'announcements.announcement.status.unread': 'Nieprzeczytane',
  'announcements.announcement.status.archived': 'Archiwum',
  'announcements.announcement.archived': 'Zarchiwizuj',
  'announcements.announcement.archivePopup.title': 'Czy napewno chcesz zarchiwizować ogłoszenie nr {announcementNumber}?',
  'announcements.announcement.archivePopup.description': 'Archiwizacja ogłoszenia nie może zostać cofnięta',

  'tickets.ticket.ticket': 'Zgłoszenie',
  'tickets.ticket.id': 'ID',
  'tickets.ticket.describeProblem': 'Opisz swój problem',
  'tickets.ticket.ticketNumber': 'ID',
  'tickets.ticket.description': 'Opis',
  'tickets.ticket.title': 'Tytuł',
  'tickets.ticket.addAttachment': 'Dodaj załącznik',
  'tickets.ticket.addAttachmentDescription': 'Opisz',
  'tickets.ticket.reportedAt': 'Data utworzenia',
  'tickets.ticket.lastCommentAt': 'Ostatni komentarz',
  'tickets.ticket.homeownerAssociation': 'Wspólnota',
  'tickets.ticket.homeownerAssociationCode': 'Kod wspólnoty',
  'tickets.ticket.tenant': 'Lokator',
  'tickets.ticket.homeownerAssociationName': 'Nazwa wspólnoty',
  'tickets.ticket.venue': 'Lokal',
  'tickets.ticket.venueAddress': 'Adres lokalu',
  'tickets.ticket.venueCode': 'Kod lokalu',
  'tickets.ticket.homeownerAssociationCodeName': 'Kod/nazwa wspólnoty',
  'tickets.ticket.venueCodeAddress': 'Kod/adres lokalu',
  'tickets.ticket.assignedTo': 'Realizujący',
  'tickets.ticket.unAssigned': 'nieprzypisano',
  'tickets.ticket.reporter': 'Zgłosił',
  'tickets.ticket.reporterCode': 'Kod zgłaszającego',
  'tickets.ticket.reporterName': 'Zgłaszający',
  'tickets.ticket.realizerCode': 'Kod realizującego',
  'tickets.ticket.realizer': 'Realizujący',
  'tickets.ticket.category': 'Kategoria',
  'tickets.ticket.reporterNameCode': 'Zgłaszający',
  'tickets.ticket.realizerNameCode': 'Realizujący',
  'tickets.ticket.status': 'Status',
  'tickets.ticket.status.open': 'Otwarte',
  'tickets.ticket.status.ongoing': 'W trakcie',
  'tickets.ticket.status.completed': 'Wykonane',
  'tickets.ticket.date': 'Data utworzenia',
  'tickets.ticket.placeholder': 'Przycisk zobacz szczegóły zgłoszenia',
  'tickets.ticket.ticketDetails': 'Zobacz szczegóły zgłoszenia',
  'tickets.ticket.titleOrNumber': 'Tytuł lub numer',
  'tickets.ticket.changesNotifier': 'Powiadom o zmianie e-mailem',
  'tickets.ticket.checkbox.label': 'Zaznacz',
  'tickets.ticket.error.attachment.biggerThan5mb': 'Załącznik nie może być większy niż 5mb',
  'tickets.ticket.error.attachment.wrongFileType': 'Akceptujemy tylko pliki jpg, png, pdf',
  'tickets.ticket.error.title.required': 'Tytuł jest wymagany',
  'tickets.ticket.error.title.maxLength': 'Tytuł nie może być dłuższy niż 150 znaków',
  'tickets.ticket.error.description.required': 'Opis jest wymagany',
  'tickets.ticket.error.category.required': 'Kategoria jest wymagana',
  'tickets.ticket.comments.discussion': 'Dyskusja',
  'tickets.ticket.comments.discussionWithTenant': 'Dyskusja z lokatorem',
  'tickets.ticket.comments.discussionWithEmployee': 'Dyskusja z pracownikiem',
  'tickets.ticket.comments.me': 'Ja',
  'tickets.ticket.comments.write': 'Napisz...',
  'tickets.ticket.comments.comment': 'Wpisz komentarz',
  'tickets.ticket.comments.noComments': 'Napisz wiadomość żeby rozpocząć dyskusję',
  'tickets.ticket.comments.error.content.required': 'Komentarz nie może być pusty',
  'tickets.ticket.relatedTasks': 'Powiązane {tasksCount} {tasksCount, plural, one {zadanie} other {zadania}}:',
  'tickets.ticket.addTask': 'Dodaj zadanie',
  'tickets.ticket.changeTicketsStatus': 'Zmień status dla wybranych zgłoszeń',
  'tickets.ticket.changeTicketsCategory': 'Zmień kategorię dla wybranych zgłoszeń',
  'tickets.ticket.changeTicketsRealizer': 'Zmień osobę realizującą dla wybranych zgłoszeń',

  'tickets.column.ticket_number': 'Numer',
  'tickets.column.title': 'Tytuł',
  'tickets.column.status': 'Status',
  'tickets.column.category': 'Kategoria',
  'tickets.column.reported_at': 'Data utworzenia',
  'tickets.column.last_comment_at': 'Data odpowiedzi',
  'tickets.column.reporter': 'Zgłaszający',
  'tickets.column.reporter_external_id': 'Kod zgłaszającego',
  'tickets.column.realizer': 'Realizujący',
  'tickets.column.realizer_external_id': 'Kod realizującego',
  'tickets.column.venue_address': 'Adres lokalu',
  'tickets.column.venue_code': 'Kod lokalu',
  'tickets.column.homeowner_association_name': 'Nazwa wspólnoty',
  'tickets.column.homeowner_association_code': 'Kod wspólnoty',
  'tickets.bulkAction.changeRealizer': 'Zmień osobę realizującą',
  'tickets.bulkAction.changeCategory': 'Zmień kategorię',
  'tickets.bulkAction.changeStatus': 'Zmień status',

  'tasks.header': 'Zadania',
  'tasks.tabs.open': 'Otwarte',
  'tasks.tabs.unassigned': 'Nieprzypisane',
  'tasks.tabs.all': 'Wszystkie zadania',
  'tasks.column.status': 'Status',
  'tasks.column.creationDate': 'Data utworzenia',
  'tasks.column.lastCommentDate': 'Ostatnia odpowiedź',
  'tasks.column.number': 'Numer',
  'tasks.column.title': 'Tytuł',
  'tasks.column.venueAddress': 'Adres lokalu',
  'tasks.column.venueCode': 'Kod lokalu',
  'tasks.column.homeownerAssociation': 'Nazwa wspólnoty',
  'tasks.column.homeownersAssociationCode': 'Kod wspólnoty',
  'tasks.column.category': 'Kategoria',
  'tasks.column.reporterName': 'Zgłaszający',
  'tasks.column.reporterCode': 'Kod zgłaszającego',
  'tasks.column.realizerCode': 'Kod realizującego',
  'tasks.column.realizer': 'Realizujący',
  'tasks.column.startDate': 'Data rozpoczęcia',
  'tasks.column.plannedStartDate': 'Data plan. rozpoczęcia',
  'tasks.column.endDate': 'Data zakończenia',
  'tasks.column.plannedEndDate': 'Data plan. zakończenia',
  'tasks.bulkAction.changeRealizer': 'Zmień osobę realizującą',
  'tasks.bulkAction.changeCategory': 'Zmień kategorię',
  'tasks.bulkAction.changeStatus': 'Zmień status',
  'tasks.column.placeholder': 'Przycisk zobacz szczegóły zadania',
  'tasks.task.status.open': 'Otwarte',
  'tasks.task.status.ongoing': 'W trakcie',
  'tasks.task.status.completed': 'Wykonane',
  'tasks.filter.venueSearch': 'Kod/adres lokalu',
  'tasks.filter.homeownersAssociationSearch': 'Kod/nazwa wspólnoty',
  'tasks.filter.numberTitle': 'Numer/tytuł',
  'tasks.filter.creatorNameCode': 'Zgłaszający',
  'tasks.filter.realizerNameCode': 'Realizujący',
  'tasks.changeTasksRealizer.popUp.label': 'Zmień osobę realizującą dla wybranych zadań',
  'tasks.changeTasksRealizer.popUp.or': 'lub',
  'tasks.changeTasksRealizer.popUp.removeRealizer': 'Usuń osobę realizującą dla wybranych zadań',
  'tasks.changeTasksCategory': 'Zmień kategorię dla wybranych zadań',
  'tasks.changeTasksStatus': 'Zmień status dla wybranych zadań',
  'tasks.checkbox.label': 'Zaznacz',

  'tasks.columnConfig.status': 'Status',
  'tasks.columnConfig.created_at': 'Data utworzenia',
  'tasks.columnConfig.last_comment_at': 'Data odpowiedzi',
  'tasks.columnConfig.task_number': 'Numer',
  'tasks.columnConfig.title': 'Tytuł',
  'tasks.columnConfig.venue_address': 'Adres lokalu',
  'tasks.columnConfig.venue_code': 'Kod lokalu',
  'tasks.columnConfig.homeowner_association_code': 'Kod wspólnoty',
  'tasks.columnConfig.homeowner_association_name': 'Nazwa wspólnoty',
  'tasks.columnConfig.category': 'Kategoria',
  'tasks.columnConfig.creator': 'Zgłaszający',
  'tasks.columnConfig.creator_external_id': 'Kod zgłaszającego',
  'tasks.columnConfig.realizer': 'Realizujący',
  'tasks.columnConfig.realizer_external_id': 'Kod realizującego',
  'tasks.columnConfig.estimated_start_time': 'Data plan. rozpoczęcia',
  'tasks.columnConfig.estimated_finish_time': 'Data plan. zakończenia',
  'tasks.columnConfig.started_at': 'Data rozpoczęcia',
  'tasks.columnConfig.finished_at': 'Data zakończenia',

  'tasks.newTask': 'Nowe zadanie',
  'tasks.newTask.taskToTicket': 'Zadanie do zgłoszenia:',
  'tasks.newTask.taskToDocument': 'Zadanie do dokumentu:',
  'tasks.newTask.category': 'Kategoria',
  'tasks.newTask.title': 'Tytuł',
  'tasks.newTask.description': 'Opis',
  'tasks.newTask.status': 'Status',
  'tasks.newTask.realizer': 'Realizujący',
  'tasks.newTask.attachment.add': 'Dodaj załącznik',
  'tasks.newTask.attachment.placeholder': 'Opisz...',
  'tasks.newTask.tenant': 'Lokator',
  'tasks.newTask.venue': 'Lokal',
  'tasks.newTask.homeownerAssociation': 'Wspólnota',
  'tasks.newTask.estimated_start_time': 'Data plan. rozpoczęcia',
  'tasks.newTask.estimated_finish_time': 'Data plan. zakończenia',
  'tasks.newTask.started_at': 'Data rozpoczęcia',
  'tasks.newTask.finished_at': 'Data zakończenia',
  'tasks.newTask.title.error.required': 'Tytuł jest wymagany.',
  'tasks.newTask.title.error.maxLength': 'Tytuł nie może być dłuższy niż 150 znaków',
  'tasks.newTask.description.error.required': 'Opis jest wymagany.',
  'tasks.newTask.category.error.required': 'Kategoria jest wymagana.',
  'tasks.newTask.status.error.required': 'Status jest wymagany.',

  'tasks.task': 'Zadanie',
  'tasks.task.status': 'Status',
  'tasks.task.category': 'Kategoria',
  'tasks.task.realizer': 'Realizujący',
  'tasks.task.startedAt': 'Data rozpoczęcia',
  'tasks.task.finishedAt': 'Data zakończenia',
  'tasks.task.ticket': 'Zgłoszenie',
  'tasks.task.ticket.creator': 'Zgłaszający',
  'tasks.task.ticket.homeownerAssociation': 'Wspólnota',
  'tasks.task.ticket.venue': 'Lokal',
  'tasks.task.comments.discussion': 'Dyskusja',
  'tasks.task.taskDetails': 'Zobacz szczegóły zadania',
  'tasks.task.createdAt': 'Data utworzenia',
  'tasks.task.estimatedStart.estimatedEnd': 'Planowana data rozpoczęcia/zakończenia',
  'tasks.task.goToTicket': 'Otwórz zgłoszenie nr {ticketNumber}',
  'tasks.task.goToTask': 'Otwórz zadanie numer {taskNumberTitle}',
  'tasks.date.from': 'od',
  'tasks.date.until': 'do',
  'tasks.task.document': 'Dokument',
  'tasks.task.document.creator': 'Autor',
  'tasks.task.document.title': 'Tytuł',
  'tasks.task.document.journal': 'Dziennik',

  'documents.list.title': 'Dokumenty',
  'documents.list.newDocument': 'Tworzenie dokumentu',
  'documents.column.date': 'Data utworzenia',
  'documents.column.title': 'Tytuł',
  'documents.column.description': 'Treść',
  'documents.column.journal': 'Dziennik',
  'documents.column.creator': 'Autor',
  'documents.column.realizer': 'Realizujący',
  'documents.column.documentNumber': 'Numer',
  'documents.column.sender': 'Nadawca',
  'documents.column.receiver': 'Odbiorca',
  'documents.column.tenant': 'Lokator',
  'documents.column.category': 'Kategoria',
  'documents.column.status': 'Status',
  'documents.filter.titleOrNumber': 'Tytuł lub numer',
  'documents.document.checkbox.label': 'Zaznacz',
  'documents.document.placeholder': 'Przycisk zobacz szczegóły dokumentu',
  'documents.document.documentDetails': 'Zobacz szczegóły dokumentu',
  'documents.newDocument': 'Tworzenie dokumentu',
  'documents.document.title': 'Tytuł',
  'documents.document.journal': 'Dziennik',
  'documents.document.documentNumber.auto': 'Numeracja automatyczna',
  'documents.document.creator': 'Autor',
  'documents.document.realizer': 'Realizujący',
  'documents.document.documentNumber': 'Numer',
  'documents.document.sender': 'Nadawca',
  'documents.document.receiver': 'Odbiorca',
  'documents.document.tenant': 'Lokator',
  'documents.document.category': 'Kategoria',
  'documents.document.status': 'Status',
  'documents.document.assignedTo': 'Realizujący',
  'documents.document.description': 'Opis',
  'documents.document.error.number.required': 'Numer jest wymagany',
  'documents.document.error.journal.required': 'Dziennik jest wymagany',
  'documents.document.error.status.required': 'Status jest wymagany',
  'documents.document.error.attachment.biggerThan5mb': 'Załącznik nie może być większy niż 5mb',
  'documents.document.error.attachment.wrongFileType': 'Akceptujemy tylko pliki jpg, png, pdf',
  'documents.document.error.title.required': 'Tytuł jest wymagany',
  'documents.document.error.title.maxLength': 'Tytuł nie może być dłuższy niż 150 znaków',
  'documents.document.error.description.required': 'Opis jest wymagany',
  'documents.document.error.category.required': 'Kategoria jest wymagana',
  'documents.document.addAttachmentDescription': 'Opisz',
  'documents.document.addAttachment': 'Dodaj załącznik',
  'documents.document.header': 'Dokument',
  'documents.document.discussion': 'Dyskusja',
  'documents.document.info': 'Info',
  'documents.document.revoke': 'Wycofaj',
  'documents.document.revoked': 'Dokument wycofany',
  'documents.document.revoked.error': 'Wystąpił błąd i dokument nie został wycofany. Spróbuj ponownie później',
  'documents.document.revokePopup.title': 'Czy napewno chcesz wycofać dokument {documentNumber}',
  'documents.document.revokePopup.description': 'Wycofanie dokumentu nie może zostać cofnięte',
  'documents.changeDocumentsRealizer.popUp.label': 'Zmień osobę realizującą dla wybranych dokumentów',
  'documents.changeDocumentsRealizer.popUp.or': 'lub',
  'documents.changeDocumentsRealizer.popUp.removeRealizer': 'Usuń osobę realizującą dla wybranych dokumentów',
  'documents.bulkAction.changeCategory': 'Zmień kategorię',
  'documents.bulkAction.changeStatus': 'Zmień status',
  'documents.changeDocumentsRealizer': 'Zmień osobę realizującą dla wybranych dokumentów',
  'documents.changeDocumentsCategory': 'Zmień kategorię dla wybranych dokumentów',
  'documents.changeDocumentsStatus': 'Zmień status dla wybranych dokumentów',
  'documents.document.addTask': 'Dodaj zadanie',
  'documents.document.relatedTasks': 'Powiązane {tasksCount} {tasksCount, plural, one {zadanie} other {zadania}}:',
  'tasks.task.goToDocument': 'Otwórz dokument nr {documentNumber}',

  'documents.columnConfig.status': 'Status',
  'documents.columnConfig.sender': 'Nadawca',
  'documents.columnConfig.receiver': 'Odbiorca',
  'documents.columnConfig.tenant': 'Lokator',
  'documents.columnConfig.category': 'Kategoria',
  'documents.columnConfig.creator': 'Autor',
  'documents.columnConfig.realizer': 'Realizujący',

  'resolutions.status.ongoing': 'Aktywne',
  'resolutions.status.outvoted': 'Przyjęto',
  'resolutions.status.rejected': 'Odrzucono',
  'resolutions.voted': 'Zagłosowano: ',
  'resolutions.voteUntil': 'Głosowanie do:',
  'resolutions.createdAt': 'Utworzono',
  'resolutions.voting': 'Głosowanie',
  'resolutions.display': 'Zapoznaj się',
  'resolutions.displayAriaLabel': 'Zapoznaj się z uchwałą {title}',
  'resolutions.resolution': 'Uchwała',
  'resolutions.resolution.votingActive': 'Głosowanie otwarte',
  'resolutions.resolution.createdAt': 'Data uchwały: ',
  'resolutions.resolution.voteUntil': 'Prosimy zagłosować do: ',
  'resolutions.resolution.attachmentsNotice': 'Zanim zagłosujesz musisz zapoznać się z treścią załączników',
  'resolutions.resolution.attachmentsAccepted': 'Zapoznano się z treścią załączników, przejdź do głosowania',
  'resolutions.resolution.next': 'Następny',
  'resolutions.resolution.previous': 'Poprzedni',
  'resolutions.resolution.acceptedContent': 'Zapoznano',
  'resolutions.resolution.goToVoting': 'Przejdź do głosowania',
  'resolutions.resolution.vote': 'Zagłosuj',
  'resolutions.resolution.myVote': 'Mój głos',
  'resolutions.resolution.voted': 'Zagłosowano',
  'resolutions.resolution.voteSubmitted': 'Dziękujemy, Twój głos został przesłany',
  'resolutions.resolution.voteOptions.agree': 'Za',
  'resolutions.resolution.voteOptions.disagree': 'Przeciw',
  'resolutions.resolution.voteOptions.abstain': 'Wstrzymuję się',
  'resolutions.resolutions.voting.error.required': 'Proszę wybrać jedną z powyżych opcji',
  'resolutions.resolutions.voting.error.internalError': 'Wystąpił błąd wewnętrzny i nie udało się zagłosować. Spróbuj później lub skontaktuj się z administratorem.',
  'resolutions.tooltipText': 'Oddane online / Do oddania online',
  'resolutions.tabs.all': 'Wszystkie',
  'resolutions.tabs.active': 'Aktywne',
  'resolutions.tabs.closed': 'Zamknięte',
  'resolutions.filters.title': 'Tytuł',
  'resolutions.filters.state': 'Stan głosowania',
  'resolutions.filters.creationDate': 'Data utworzenia',
  'resolutions.filters.voteDate': 'Termin głosowania',
  'resolutions.filters.state.voted': 'Zagłosowano',
  'resolutions.filters.state.notVoted': 'Nie zagłosowano',
  'resolutions.filters.homeownerAssociationCodeName': 'Kod/nazwa wspólnoty',
  'resolutions.filters.number': 'Numer',
  'resolutions.column.homeownerAssociationCode': 'Kod wspólnoty',
  'resolutions.column.resolutionNumber': 'Numer uchwały',
  'resolutions.column.title': 'Tytuł',
  'resolutions.column.onlineVotes': 'Głosy online',
  'resolutions.column.createdAt': 'Data utworzenia',
  'resolutions.column.votingDeadline': 'Termin głosowania',
  'resolutions.column.status': 'Status',
  'resolutions.votings.onlineVotesGiven': 'Oddano głosów online: {pwiVotes}',
  'resolutions.votings.column.tenantExternalId': 'Kod lokatora',
  'resolutions.votings.column.tenantName': 'Imię i nazwisko',
  'resolutions.votings.column.dataSource': 'Sposób głosowania',
  'resolutions.votings.column.votedAt': 'Zagłosowano',
  'resolutions.votings.column.votedValue': 'Głos',
  'resolutions.votings.noVoteValue': 'Brak',
  'resolutions.votings.agree': 'Za',
  'resolutions.votings.disagree': 'Przeciw',
  'resolutions.votings.abstain': 'Wstrzymano',
  'resolutions.votings.filters.tenantExternalIdFullName': 'Kod/Imię i nazwisko',
  'resolutions.votings.filters.votingVia': 'Sposób głosowania',
  'resolutions.votings.filters.voteDate': 'Data zagłosowania',
  'resolutions.votings.filters.voteValue': 'Głos',
  'resolutions.votings.filters.votedOnline': 'online',
  'resolutions.votings.filters.votedNotOnline': 'Inne',

  'profile.user.id': 'Identyfikator',
  'profile.user.name': 'Imię i nazwisko lub nazwa firmy',
  'profile.user.email': 'E-mail',
  'profile.user.tenantEmail': 'E-mail w systemie Czynsze',
  'profile.user.login': 'Login',
  'profile.user.password': 'Hasło',
  'profile.user.phone': 'Telefon',
  'profile.user.address': 'Adres korespondencyjny',
  'profile.user.postalCode': 'Kod pocztowy',
  'profile.user.city': 'Miasto',
  'profile.user.group': 'Grupa Użytkownika',
  'profile.user.agreement': 'Zgoda na otrzymywanie powiadomień e-mail',
  'profile.user.error.required': 'Nie może być puste',
  'profile.user.success': 'Dane zostały zaktualizowane',
  'profile.user.changePassword': 'Zmień hasło',
  'profile.user.changePassword.success': 'Hasło zostało zmienione',
  'profile.user.changePassword.oldPassword': 'Podaj stare hasło',
  'profile.user.changePassword.newPassword': 'Podaj nowe hasło',
  'profile.user.changePassword.confirmNewPassword': 'Powtórz nowe hasło',
  'profile.user.changePassword.oldPassword.error.required': 'Stare hasło jest wymagane',
  'profile.user.changePassword.newPassword.error.required': 'Nowe hasło jest wymagane',
  'profile.user.changePassword.confirmNewPassword.error.required': 'Należy powtórzyć hasło',
  'profile.user.changePassword.error.newPassword.pattern': 'Nowe hasło musi posiadać minimalnie 8 znaków i zawierać przynajmniej jedną małą, jedną wielką literę, cyfrę i znak specjalny',
  'profile.user.changePassword.error.changePassword.pattern': 'Powtórzone hasło musi posiadać minimalnie 8 znaków i zawierać przynajmniej jedną małą, jedną wielką literę, cyfrę i znak specjalny',
  'profile.user.changePassword.passwordChangeRequired': 'Zmień hasło aby zacząć korzystać z PRO Mieszkaniec',
  'profile.user.activityLog.date': 'Data',
  'profile.user.activityLog.description': 'Opis',
  'profile.user.activityLog.menuOption': 'Opcja menu',
  'profile.user.activityLog.venue': 'Lokal',
  'profile.user.activityLog.id': 'ID',
  'profile.user.activityLog.fullName': 'Imię i nazwisko',
  'profile.user.activityLog.role': 'Rola',
  'profile.user.activityLog.menuOption.resolutions': 'Uchwały',
  'profile.user.activityLog.menuOption.tasks': 'Zadania',
  'profile.user.activityLog.menuOption.tickets': 'Zgłoszenia',
  'profile.user.activityLog.menuOption.authentication': 'Logowanie',
  'profile.user.activityLog.menuOption.meters': 'Liczniki',
  'profile.user.activityLog.menuOption.profiles': 'Profil',
  'profile.user.activityLog.menuOption.residency_amendments': 'Zmiana liczby osób',
  'profile.user.activityLog.menuOption.settlements': 'Płatności',
  'profile.user.activityLog.menuOption.announcements': 'Aktualności',
  'profile.settings.color': 'Kolor',

  'users.name': 'Nazwa',
  'users.other': 'Inne',
  'user.role.permissionCount': '{count} {count, plural, one {uprawnienie} few {uprawnienia} other {uprawnień}}',
  'user.additionalPermissions': 'Dodatkowe uprawnienia',
  'user.noAdditionalPermissions': 'Użytkownik nie ma obecnie dodatkowych uprawnień',
  'user.managePermissions': 'Zarządzaj uprawnieniami',
  'user.permissionsChanged': 'Uprawnienia Użytkownika zostały zmienione',
  'user.roles.deleteRole': 'Usuń rolę {roleName}',
  'user.roles.confirmation.changeRole.header': 'Czy na pewno chcesz to zmienić?',
  'user.roles.confirmation.changeRole.affectedUsers': 'Zmiana ta dotyczy roli, z której korzysta {usersCount} {usersCount, plural, one {osoba} few {osoby} other {osób}}.',
  'user.roles.confirmation.deleteRole.header': 'Usunąć role {roleName}?',
  'user.roles.confirmation.deleteRole.description': 'Czy na pewno chcesz usunąć rolę {roleName}?',
  'user.roles.confirmation.deleteRole.affectedUsers': 'Nie można usunąć roli {roleName}? {usersCount} {usersCount, plural, one {osoba} few {osoby} other {osób}} wciąż {usersCount, plural, few {korzystają} other {korzysta}} z tej roli.',
  'user.roles.createRole': 'Stwórz rolę',
  'user.roles.fillName': 'Wpisz nazwę...',
  'user.roles.validation.nameRequired': 'Należy podać nazwę',
  'user.roles.validation.nameUnique': 'Nazwa musi być unikalna',

  'users.header': 'Użytkownicy',
  'users.tabs.all': 'Wszyscy',
  'users.tabs.workers': 'Pracownicy',
  'users.tabs.activityLog': 'Rejestr zdarzeń',
  'users.column.id': 'Identyfikator',
  'users.column.name': 'Użytkownik',
  'users.column.login': 'Login',
  'users.column.roles': 'Role',
  'users.column.email': 'Email',
  'users.filter.roleType': 'Rola',
  'users.filter.fullName': 'Użytkownik',
  'users.filter.login': 'Login',
  'users.newUser.create': 'Utwórz',
  'users.newUser.header': 'Nowy Użytkownik',
  'users.newUser.externalId': 'Identyfikator*',
  'users.newUser.roles': 'Role*',
  'users.newUser.status': 'Status',
  'users.newUser.status.active': 'Aktywny',
  'users.newUser.status.notActive': 'Nieaktywny',
  'users.newUser.login': 'Login',
  'users.newUser.name': 'Imię i nazwisko lub nazwa firmy*',
  'users.newUser.email': 'E-mail*',
  'users.newUser.phone': 'Telefon',
  'users.newUser.email.error.required': 'Email jest wymagany',
  'users.newUser.roles.error.required': 'Rola jest wymagana',
  'users.newUser.name.error.required': 'Imię i nazwisko lub nazwa firmy jest wymagana',
  'users.newUser.id.error.required': 'Identyfikator jest wymagany',
  'users.user.status': 'Status',
  'users.user.status.active': 'Aktywny',
  'users.user.status.notActive': 'Nieaktywny',
  'users.user.roles': 'Role*',
  'users.user.roles.error.required': 'Rola jest wymagana',
  'users.user.roles.error.meAdmin': 'Administrator nie może odpiąć roli administratora samemu sobie. Jeśli chcesz usunąć rolę admina, skontaktuj się z Super Administratorem',
  'users.user.login': 'Login',
  'users.user.name': 'Imię i nazwisko lub nazwa firmy*',
  'users.user.name.error.required': 'Imię i nazwisko lub nazwa firmy jest wymagana',
  'users.user.email': 'E-mail*',
  'users.user.email.error.required': 'Email jest wymagany',
  'users.user.phone': 'Telefon',
  'users.user.activate': 'Aktywuj',
  'users.user.deactivate': 'Dezaktywuj',
  'users.user.activate.popup': 'Czy na pewno chcesz aktywować użytkownika {userName}?',
  'users.user.deactivate.popup': 'Czy na pewno chcesz dezaktywować użytkownika {userName}?',
  'users.user.accessGroups': 'Grupy dostępu',
  'users.user.accessGroup.homeownerAssociations': 'Wspólnota',
  'users.user.accessGroup.estates': 'Osiedle',
  'users.user.accessGroup.buildings': 'Budynki',
  'users.user.accessGroup.journals': 'Dzienniki',
  'users.user.resetUserPassword': 'Zresetuj hasło',
  'users.user.changePassword.success': 'Hasło zostało zmienione',
  'users.user.changePassword.newPassword': 'Podaj nowe hasło',
  'users.user.changePassword.confirmNewPassword': 'Powtórz nowe hasło',
  'users.user.changePassword.newPassword.error.required': 'Hasło jest wymagane',
  'users.user.changePassword.error.pattern': 'Hasło musi posiadać minimalnie 8 znaków i zawierać przynajmniej jedną małą, jedną wielką literę, cyfrę i znak specjalny',
  'users.user.changePassword.confirmNewPassword.error.required': 'Należy powtórzyć hasło',
  'users.activityLog.date': 'Data',
  'users.activityLog.description': 'Opis',
  'users.activityLog.menuOption': 'Opcja menu',
  'users.activityLog.venue': 'Lokal',
  'users.activityLog.id': 'ID',
  'users.activityLog.fullName': 'Imię i nazwisko',
  'users.activityLog.role': 'Rola',
  'users.activityLog.menuOption.resolutions': 'Uchwały',
  'users.activityLog.menuOption.tasks': 'Zadania',
  'users.activityLog.menuOption.tickets': 'Zgłoszenia',
  'users.activityLog.menuOption.authentication': 'Logowanie',
  'users.activityLog.menuOption.meters': 'Liczniki',
  'users.activityLog.menuOption.profiles': 'Profil',
  'users.activityLog.menuOption.residency_amendments': 'Zmiana liczby osób',
  'users.activityLog.menuOption.settlements': 'Płatności',
  'users.activityLog.menuOption.announcements': 'Aktualności',

  'parameters.statuses.tickets': 'Zgłoszenia',
  'parameters.statuses.tasks': 'Zadania',
  'parameters.statuses.documents': 'Dokumenty',
  'parameters.statuses.list.active': 'Aktywny',
  'parameters.statuses.list.externalName': 'Nazwa zewnętrzna',
  'parameters.statuses.list.internalName': 'Nazwa wewnętrzna',
  'parameters.statuses.status': 'Status - {statusName}',
  'parameters.statuses.form.active': 'Aktywny',
  'parameters.statuses.form.internalName': 'Nazwa wewnętrzna',
  'parameters.statuses.form.externalName': 'Nazwa zewnętrzna',
  'parameters.statuses.form.description': 'Opis',
  'parameters.statuses.form.internalName.required': 'Nazwa wewnętrzna jest wymagana',
  'parameters.statuses.form.externalName.required': 'Nazwa zewnętrzna jest wymagana',

  'parameters.journals.prefixes': 'Prefiksy',
  'parameters.journals.name': 'Nazwa',
  'parameters.journals.numeration': 'Numeracja',
  'parameters.journals.autonumbering': 'automatyczna',
  'parameters.journals.create': 'Utwórz',
  'parameters.journals.newJournal': 'Nowy dziennik',
  'parameters.journals.journal': 'Dziennik - {journalName}',
  'parameters.journals.journal.confirmRemoving': 'Czy na pewno chcesz usunąć dziennik - {journalName}',
  'parameters.statuses.form.autonumbering': 'Autonumeracja',
  'parameters.statuses.form.prefix': 'Prefix',
  'parameters.statuses.form.prefix.required': 'Prefix jest wymagany',
  'parameters.statuses.form.name': 'Nazwa',
  'parameters.statuses.form.name.required': 'Nazwa jest wymagana',

  'parameters.categories': 'Kategorie',
  'parameters.categories.add': 'Dodaj',
  'parameters.categories.change': 'Zmień',
  'parameters.categories.remove': 'Usuń',

  'parameters.categories.addDialog.header': 'Dodaj nową kategorię',
  'parameters.categories.addDialog.button': 'Dodaj',
  'parameters.categories.changeDialog.header': 'Zmień nazwę kategorii',
  'parameters.categories.changeDialog.button': 'Zmień',
  'parameters.categories.deleteDialog.header': 'Czy na pewno usunąć kategorię?',
  'parameters.categories.deleteDialog.ticketsSubHeader': 'Kategorię można usunąć tylko jeśli nie istnieją zgłoszenia z daną kategorią',
  'parameters.categories.deleteDialog.tasksSubHeader': 'Kategorię można usunąć tylko jeśli nie istnieją zadania z daną kategorią',
  'parameters.categories.deleteDialog.documentsSubHeader': 'Kategorię można usunąć tylko jeśli nie istnieją dokumenty z daną kategorią',
  'parameters.categories.deleteDialog.error': 'Nie można usunąć kategorii.',
  'parameters.categories.deleteDialog.button': 'Usuń',

  'parameters.categories.dialog.name': 'Nazwa',
  'parameters.categories.dialog.name.error': 'Nazwa jest wymagana',

  'pagination.info': 'Wyświetlono {start}-{end} z {all} pozycji',
  'pagination.previousPage': 'Poprzednia strona',
  'pagination.nextPage': 'następna strona',

  'datepicker.month.january': 'Styczeń',
  'datepicker.month.february': 'Luty',
  'datepicker.month.march': 'Marzec',
  'datepicker.month.april': 'Kwiecień',
  'datepicker.month.may': 'Maj',
  'datepicker.month.june': 'Czerwiec',
  'datepicker.month.july': 'Lipiec',
  'datepicker.month.august': 'Sierpień',
  'datepicker.month.september': 'Wrzesień',
  'datepicker.month.october': 'Październik',
  'datepicker.month.november': 'Listopad',
  'datepicker.month.december': 'Grudzień',

  'datepicker.week.monday': 'pon.',
  'datepicker.week.tuesday': 'wt.',
  'datepicker.week.wednesday': 'śr.',
  'datepicker.week.thursday': 'czw.',
  'datepicker.week.friday': 'pt.',
  'datepicker.week.saturday': 'sob.',
  'datepicker.week.sunday': 'ndz.',

  'select.placeholder': 'Wybierz',

  'permissions.roleType.admin': 'Admin',
  'permissions.roleType.tenant': 'Lokator',
  'permissions.roleType.worker': 'Pracownik',
  'permissions.roleType.member': 'Członek',

  'permissions.meters': 'Liczniki',
  'permissions.meters.meters_list': 'Wyświetlanie listy liczników do odczytu',
  'permissions.meters.meters_measurement_add': 'Dodawanie odczytów liczników',
  'permissions.meters.meters_history_list': 'Wyświetlanie historii liczników',
  'permissions.tickets': 'Zgłoszenia',
  'permissions.tickets.tickets_add': 'Raportowanie',
  'permissions.tickets.tickets_list': 'Wyświetlanie listy zgłoszeń',
  'permissions.resolutions': 'Uchwały',
  'permissions.resolutions.resolutions_list': 'Wyświetlanie uchwał',
  'permissions.resolutions.resolutions_vote_add': 'Głosowanie',
  'permissions.resolutions.resolutions_votings_list': 'Wyświetlanie listy głosów do uchwały',
  'permissions.announcements': 'Aktualności',
  'permissions.announcements.announcements_list': 'Wyświetlanie aktualności',
  'permissions.announcements.announcements_notice_add': 'Tworzenie ogłoszeń',
  'permissions.documents': 'Dokumenty',
  'permissions.documents.documents_list': 'Wyświetlanie listy dokumentów',
  'permissions.documents.documents_add': 'Dodawanie dokumentów',
  'permissions.tenants': 'Lokatorzy',
  'permissions.tenants.tenants_people_entries_manage': 'Zmiana liczby lokatorów',
  'permissions.tasks': 'Zadania',
  'permissions.tasks.tasks_list': 'Wyświetlanie listy zadań',
  'permissions.tasks.tasks_add': 'Tworzenie zadania',
  'permissions.activity_log': 'Rejestr zdarzeń',
  'permissions.activity_log.activity_log_register_entries_list': 'Wyświetlanie listy zdarzeń',
  'permissions.settlements': 'Rozrachunki',
  'permissions.settlements.settlements_balance_list': 'Wyświetlanie analizy salda',
  'permissions.settlements.settlements_payments_list': 'Wyświetlanie listy płatności',
  'permissions.settlements.settlements_payment_make': 'Dokonywanie płatności',
  'permissions.settlements.settlements_document_read': 'Odczytywanie dokumentów rozrachunkowych',

  'attachment.previewUnavailable': 'Podgląd niedostępny',
  'attachment.previewUnavailable.download': 'Podgląd niedostępny. Pobierz załącznik aby zapoznać się z jego treścią.',
  'attachment.download': 'Pobierz',

  'company.details.name': 'Nazwa podmiotu',
  'company.details.postalCode': 'Kod pocztowy',
  'company.details.city': 'Miasto',
  'company.details.streetAddress': 'Ulica, nr domu/nr lokalu',
  'company.details.phone': 'Telefon',
  'company.details.website': 'Strona www',
  'company.details.form.name.error.required': 'Nazwa jest wymagana',
  'company.details.addLogo': 'Dodaj logo',
  'company.details.changeLogo': 'Zmień logo',
  'company.details.changeBackgroundImage': 'Zmień zdjęcie',
  'company.details.addBackgroundImage': 'Dodaj zdjęcie',
  'company.details.changeRegulations': 'Zmień regulamin',
  'company.details.addRegulations': 'Dodaj regulamin',
  'company.details.backgroundImage': 'Zdjęcie',
  'company.details.regulations': 'Regulamin',
  'company.attachments.success': 'Pliki zostały zaktualizowane',
  'company.attachments.error': 'Wystąpił błąd i pliki nie zostały zapisane',
  'company.settings.colorPalette': 'Wygląd',
  'company.settings.colorPalette.red': 'Czerwony',
  'company.settings.colorPalette.green': 'Zielony',
  'company.settings.colorPalette.violet': 'Fioletowy',
  'company.settings.colorPalette.default': 'Domyślny',
  'company.mailServerConfig.form.address': 'Adres',
  'company.mailServerConfig.form.domain': 'Domena',
  'company.mailServerConfig.form.port': 'Port',
  'company.mailServerConfig.form.userName': 'Nazwa użytkownika',
  'company.mailServerConfig.form.authentication': 'Autentykacja',
  'company.mailServerConfig.form.communicationEncryption': 'Szyfrowanie transmisji SMTP',
  'company.mailServerConfig.form.password': 'Hasło',
  'company.mailServerConfig.form.password.button.change': 'Zmień hasło',
  'company.mailServerConfig.form.password.button.set': 'Ustaw hasło',
  'company.mailServerConfig.form.fromEmail': 'Email nadawcy',
  'company.mailServerConfig.form.error.required': 'Nie może być puste',
  'company.mailServerConfig.test.form.label': 'Przetestuj poprawność konfiguracji serwera',
  'company.mailServerConfig.test.form.email': 'Wprowadź e-mail, na który chcesz wysłać testową wiadomość',
  'company.mailServerConfig.test.form.email.required': 'Nie może być pusty',
  'company.mailServerConfig.test.form.button': 'Testuj',
  'company.mailServerConfig.test.success': 'Testowy mail został wysłany. Jeśli serwer został poprawnie skonfigurowany, mail trafi do skrzynki mailowej',
  'company.mailServerConfig.test.error': 'Testowy mail nie został wysłany. Ponownie wypełnij konfigurację serwera, a następnie wyślij testowy email.',

  'fallbackRender.error.404.header': 'Błąd 404: Strona, której szukasz nie istnieje lub została usunięta',
  'fallbackRender.error.404.paragraph': 'Sprawdź poprawność adresu lub ',
  'fallbackRender.header': 'Przepraszamy, wystąpił nieoczekiwany błąd',
  'fallbackRender.backToHomepage': 'wróć do strony głównej',
};

export default messages;
